import { required,maxLength,propObject,numeric,NumericValueType } from "@rxweb/reactive-form-validators";
import { Involucrado } from './involucrados';

export class Registro{

    id_registro:BigInteger;

    @required()
    rfc_firma: string;

    @required()
    nombre_firma: string;

    @required()
    nombre_reunion: string;

    @required()
    firmante: string;

    codigoshas_inicial: string

    fecha: Date;

    nombre_archivo: string;

    tamano: Number;

    ruta: string;

    folio: string;

    num_certificado: string;

    transaccion:number;

    codigosha: string;

    involucrados: [Involucrado];

    plantilla: Number;

    estado: Number;

  }
  
