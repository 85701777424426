import { Injectable } from '@angular/core';
import { RegistroService } from '../services/registro.service';
import { Registro } from '../model/registro';
import { Plantilla } from '../model/plantilla';
import { Involucrado } from '../model/involucrados';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { PdfMakeWrapper,Table,Txt,Img, QR,Columns } from 'pdfmake-wrapper';
import * as CryptoJS from 'crypto-js'
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import pdfFonts from './../assets/fonts/montserrat-fonts'; 
import { Location } from '@angular/common';
import { NGXLogger } from 'ngx-logger';
var Buffer = require('buffer/').Buffer



@Injectable({ providedIn: 'root' })
export class CommonsShared {
    keySecrets='SFPSistConciDHGTI';
    pdfFonts;
    datosRegisto: Registro = new Registro();
    datosPlantilla: Plantilla = new Plantilla();
    url_href = '';
    private currentnReunionSubject: BehaviorSubject<string>;
    public currentnReunion: Observable<string>;
    stringUrlbase='';

    constructor( private registroService: RegistroService,private location : Location,private logger: NGXLogger) {
      this.currentnReunionSubject = new BehaviorSubject<string>(null);
      this.currentnReunion = this.currentnReunionSubject.asObservable();
      this.currentnReunionSubject.next(null);
      this.pdfFonts = require('pdfmake/build/vfs_fonts.js');
      //PdfMakeWrapper.setFonts(this.pdfFonts);
      PdfMakeWrapper.setFonts(pdfFonts, {
        monserrat: {
          normal: 'Montserrat-Regular.ttf',
          bold: 'Montserrat-Bold.ttf',
          italics: 'Montserrat-Italic.ttf',
          bolditalics: 'Montserrat-BoldItalic.ttf'
        }
      });
      PdfMakeWrapper.useFont('monserrat');
      this.stringUrlbase=this.location['_platformStrategy']._platformLocation.location.protocol+'//'+this.location['_platformStrategy']._platformLocation.location.host;
    }
    
    public get currentnReunionValue(): string {
        return this.currentnReunionSubject.value;
    }
    
    public set currentnReunionValue(value: string) {
        this.currentnReunionSubject.next(value);
    }


    public cargaRegistroV(id_registro,pdfViewerOnDemand){
        return new Promise(resolve => {
            this.registroService.getRegistrobyId(id_registro)
            .pipe(first())
            .subscribe(
              data => {
                try{
                  this.datosRegisto.rfc_firma=data.rfc_firma;
                  this.datosRegisto.folio=data.folio;
                  this.datosRegisto.num_certificado=data.numeroCertificado;
                  this.datosRegisto.transaccion=data.transaccion;
                  this.datosRegisto.codigosha=data.codigosha;
                  this.datosRegisto.id_registro=id_registro;
                  this.datosRegisto.nombre_firma=data.nombre_firma;
                  this.datosRegisto.nombre_reunion=data.nombre_reunion;
                  this.datosRegisto.codigoshas_inicial=data.codigoshas_inicial;
                  this.datosRegisto.fecha=data.fecha;
                  this.datosRegisto.nombre_archivo=data.nombre_archivo;
                  this.datosRegisto.tamano=data.tamano;
                  this.datosRegisto.ruta=data.ruta;
                  this.datosRegisto.involucrados= data.involucrados;
                  this.datosRegisto.estado=data.estado;
                  this.datosRegisto.firmante=data.firmante;
                  try{
                    this.promiseLoadPdf().then(response =>{
                      if (pdfViewerOnDemand){
                        pdfViewerOnDemand.pdfSrc= response;
                        pdfViewerOnDemand.refresh();
                        resolve(true);
                      }else{
                        resolve(response);
                      }

                    });
                  }catch (e) {
                    //msgs.push({severity:'error', summary:'Error al cargar el acuse', detail:'No pudo crear el acuse: '+ e});
                    resolve({severity:'error', summary:'Error al cargar el acuse', detail:'No pudo crear el acuse: '+ e});
                    (e)
                  }
                }catch (e) {
                  //msgs.push({severity:'error', summary:'Error al cargar el registro de firma', detail:'No cargar el registro: '+ e});
                  resolve({severity:'error', summary:'Error al cargar el registro de firma', detail:'No cargar el registro: '+ e});
                  this.logger.error(e)
                }
          
              },
              error => {
                //msgs.push({severity:'error', summary:'Error al cargar el registro de firma en la BD:', detail:error});
                resolve({severity:'error', summary:'Error al cargar el registro de firma en la BD:', detail:error});
              });
        });
      }

      public getPlantillaById(id_registro){
        return new Promise(resolve => {
          this.registroService.getPlantillabyId(id_registro)
          .pipe(first())
          .subscribe(
            data => {
              try{
                resolve(data)
              }catch (e) {
                resolve({severity:'error', summary:'Error al cargar el registro de firma', detail:'No cargar el registro: '+ e});
                this.logger.error(e)
              }
            },
            error => {
              resolve({severity:'error', summary:'Error al cargar el registro de firma en la BD:', detail:error});
            });
        });
      }


      public cargaPlantilla(id_registro,pdfViewerOnDemand){
        return new Promise(resolve => {
          this.registroService.getPlantillabyId(id_registro)
          .pipe(first())
          .subscribe(
            data => {
              //this.logger.debug('DATOS PLANTILLA', JSON.stringify(data));
              try{
                this.datosPlantilla.id_registro=id_registro;
                this.datosPlantilla.expediente=data.expediente;
                this.datosPlantilla.expProCon=data.expProCon;
                this.datosPlantilla.expEntDep=data.expEntDep;
                this.datosPlantilla.expFecha=data.expFecha;
                this.datosPlantilla.textoDoc=data.textoDoc;
                this.datosPlantilla.usuario=data.usuario;
                try{
                  this.promiseLoadPdfPlantilla().then(response =>{
                    if (pdfViewerOnDemand){
                      pdfViewerOnDemand.pdfSrc= response;
                      pdfViewerOnDemand.refresh();
                      resolve(true);
                    }else{
                      resolve(response);
                    }

                  });
                }catch (e) {
                  //msgs.push({severity:'error', summary:'Error al cargar el acuse', detail:'No pudo crear el acuse: '+ e});
                  resolve({severity:'error', summary:'Error al cargar el acuse', detail:'No pudo crear el acuse: '+ e});
                  this.logger.error(e)
                }
              }catch (e) {
                //msgs.push({severity:'error', summary:'Error al cargar el registro de firma', detail:'No cargar el registro: '+ e});
                resolve({severity:'error', summary:'Error al cargar el registro de firma', detail:'No cargar el registro: '+ e});
                this.logger.error(e)
              }
        
            },
            error => {
              //msgs.push({severity:'error', summary:'Error al cargar el registro de firma en la BD:', detail:error});
              resolve({severity:'error', summary:'Error al cargar el registro de firma en la BD:', detail:error});
            });
        });
      }

      public cargaRegistro(id_registro){
        return new Promise(resolve => {
            this.registroService.getRegistrobyId(id_registro)
            .pipe(first())
            .subscribe(
              data => {
                try{
                  let registro = new Registro();
                  registro.rfc_firma=data.rfc_firma;
                  registro.folio=data.folio;
                  registro.num_certificado=data.num_certificado;
                  registro.transaccion=data.transaccion;
                  registro.codigosha=data.codigosha;
                  registro.id_registro=id_registro;
                  registro.nombre_firma=data.nombre_firma;
                  registro.nombre_reunion=data.nombre_reunion;
                  registro.codigoshas_inicial=data.codigoshas_inicial;
                  registro.fecha=data.fecha;
                  registro.nombre_archivo=data.nombre_archivo;
                  registro.tamano=data.tamano;
                  registro.ruta=data.ruta;
                  registro.involucrados= data.involucrados;
                  registro.plantilla=data.plantilla;
                  registro.firmante=data.firmante;
                  registro.estado=data.estado;
                  resolve(registro);
                }catch (e) {
                  //msgs.push({severity:'error', summary:'Error al cargar el registro de firma', detail:'No cargar el registro: '+ e});
                  resolve({severity:'error', summary:'Error al cargar el registro de firma', detail:'No cargar el registro: '+ e});
                  this.logger.error(e)
                }
          
              },
              error => {
                //msgs.push({severity:'error', summary:'Error al cargar el registro de firma en la BD:', detail:error});
                resolve({severity:'error', summary:'Error al cargar el registro de firma en la BD:', detail:error});
              });
        });
      }

      public  cargaRegistroInv(id_registro,rfc){
        return new Promise(resolve => {
            this.registroService.getRegistroFrimabyIdRFC(id_registro,rfc)
            .pipe(first())
            .subscribe(
              data => {
                try{
                  let registro = new Involucrado();
                  if (data){
                    registro.id_registro=id_registro;
                    registro.id_involucrado= data.id_registro;
                    resolve(registro);
                  }else
                    resolve(null);
                }catch (e) {
                  this.logger.error("error: "+e);
                  resolve({severity:'warn', summary:'No puede dar de alta el registro', detail:'acurrio un error: '+e});
                }
              },
              error => {
                //msgs.push({severity:'error', summary:'Error al cargar el registro de firma en la BD:', detail:error});
                resolve({severity:'error', summary:'Error al cargar el registro de firma en la BD:', detail:error});
              });
        });
      }
      

      promiseLoadPdf = () => {
        return new Promise((resolve, reject) => {
            const pdf: PdfMakeWrapper = new PdfMakeWrapper();
            pdf.pageSize({
              width: 612,
              height: 792
            });
      
            new Img(this.url_href+'assets/images/escudo.jpg').fit([200,200]).absolutePosition(10, 10).build().then( img => {
              pdf.add(img);
              var tabla =new Table([
                [ new Txt('Subsecretaría de Combate a la Impunidad').alignment('left').font('monserrat').italics().end],
                [ new Txt('Dirección General de Controversias y Sanciones').alignment('left').font('monserrat').italics().end],
                [ new Txt('en Contrataciones Públicas').alignment('left').font('monserrat').italics().end],
                [ new Txt('Firma electrónica de documentos de conciliación').alignment('left').font('monserrat').bold().italics().end],
              ]).layout('noBorders').absolutePosition(200, 12).fontSize(9).end;
              pdf.add(tabla);
              let textoQR="||"+this.datosRegisto.id_registro+"|"+this.datosRegisto.nombre_reunion+"|"+this.datosRegisto.nombre_firma+"|"+this.datosRegisto.rfc_firma+"|"+this.datosRegisto.codigosha+'.fir|'+this.datosRegisto.nombre_archivo+"|"+this.datosRegisto.tamano.toString()+"|";
              pdf.add(new Txt( ' ' ).end);
              pdf.add(new Txt( ' ' ).end);
              pdf.add(new Txt( ' ' ).end);
              pdf.add(new Txt( ' ' ).end);
              pdf.add(new Txt( ' ' ).end);
              var tabla2 =new Table([
                [ 'Reunión:', this.datosRegisto.nombre_reunion],
                [ 'Conciliador:', this.datosRegisto.nombre_firma],
                [ 'Documento firmado:', this.datosRegisto.nombre_archivo],
                [ 'Tamaño del Documento:', this.datosRegisto.tamano.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' Bytes'],
              ]).widths([ '30%', '70%']).layout('noBorders').font('monserrat').end;
              pdf.add(tabla2);
              pdf.add(new Txt( ' ' ).end);
              pdf.add(new Txt( ' ' ).end);
              pdf.add(new Txt('Caracteres de autentificación del documento:').alignment('center').font('monserrat').italics().end);
              pdf.add(new Txt(CryptoJS.SHA256(this.datosRegisto.rfc_firma+"|"+this.datosRegisto.nombre_archivo+"|"+this.datosRegisto.tamano+"|"+this.datosRegisto.fecha).toString(CryptoJS.enc.Hex)).alignment('center').font('monserrat').italics().end);
              pdf.add(new Txt( ' ' ).end);
              pdf.add(new Txt( ' ' ).end);
              pdf.add(new Txt('Firmas electrónicas:').font('monserrat').italics().end);
              pdf.add(new Txt( ' ' ).end);
              pdf.add(new Txt( ' ' ).end);

              let datoTemp:number = this.datosRegisto.involucrados.length;
              var lineasf1 =[],lineasf2 =[];
              if (datoTemp == 0) {
                pdf.add(new Txt( `POR ${this.datosRegisto.firmante}` ).alignment('center').font('monserrat').end);
                pdf.add(new Txt( ' ' ).alignment('center').end);
                pdf.add(new Txt( ` ${this.datosRegisto.codigosha}.fir ` ).decoration('underline').alignment('center').font('monserrat').end);
                pdf.add(new Txt( this.datosRegisto.nombre_firma ).alignment('center').font('monserrat').end);
                pdf.add(new Txt( this.datosRegisto.rfc_firma ).alignment('center').font('monserrat').end);
                pdf.add(new Txt( ' ' ).end);
              } else {
                lineasf1.push([{ text: ' ' }]);
                lineasf1.push([{ text: `POR ${this.datosRegisto.firmante}`, alignment: 'center' }]);
                lineasf1.push([{ text: ' ' }]);
                lineasf1.push([{ text: ` ${this.datosRegisto.codigosha}.fir `, decoration: 'underline', alignment: 'center', fontSize: 9 }]);
                lineasf1.push([{ text: this.datosRegisto.nombre_firma, alignment: 'center' }]);
                lineasf1.push([{ text: this.datosRegisto.rfc_firma, alignment: 'center' }]);
                lineasf1.push([{ text: ' ' }]);
                for (let j = 0; j < this.datosRegisto.involucrados.length; j++) {
                  textoQR=textoQR+this.datosRegisto.involucrados[j].firmante+'|'+this.datosRegisto.involucrados[j].nombre_firma+'|'+
                  this.datosRegisto.involucrados[j].rfc_firma+"|"+this.datosRegisto.involucrados[j].codigosha+'.fir|';

                  if(j%2==0){
                    lineasf2.push([{ text: ' ' }]);
                    lineasf2.push([{ text: `POR ${this.datosRegisto.involucrados[j].firmante}`, alignment: 'center' }]);
                    lineasf2.push([{ text: ' ' }]);
                    lineasf2.push([{ text: ` ${this.datosRegisto.involucrados[j].codigosha}.fir `, decoration: 'underline', alignment: 'center', fontSize: 9 }]);
                    lineasf2.push([{ text: this.datosRegisto.involucrados[j].nombre_firma, alignment: 'center' }]);
                    lineasf2.push([{ text: this.datosRegisto.involucrados[j].rfc_firma, alignment: 'center' }]);
                    lineasf2.push([{ text: ' ' }]);
                  } else {
                    lineasf1.push([{ text: ' ' }]);
                    lineasf1.push([{ text: `POR ${this.datosRegisto.involucrados[j].firmante}`, alignment: 'center' }]);
                    lineasf1.push([{ text: ' ' }]);
                    lineasf1.push([{ text: ` ${this.datosRegisto.involucrados[j].codigosha}.fir `, decoration: 'underline', alignment: 'center', fontSize: 9 }]);
                    lineasf1.push([{ text: this.datosRegisto.involucrados[j].nombre_firma, alignment: 'center' }]);
                    lineasf1.push([{ text: this.datosRegisto.involucrados[j].rfc_firma, alignment: 'center' }]);
                    lineasf1.push([{ text: ' ' }]);
                  }
                }
              }
              if (Array.isArray(this.datosRegisto.involucrados) && this.datosRegisto.involucrados.length ){
                var tabla =new Table([
                  [ lineasf1, lineasf2],
                ]).widths([ '50%', '50%']).layout('noBorders').alignment('center').font('monserrat').end;
                pdf.add(tabla);
              }
              textoQR=textoQR+"|";
              let uno =new QR(this.stringUrlbase+'/revisionAcuses?nsession='+encodeURIComponent(this.encriptaText(textoQR))).fit(80).absolutePosition(500, 650).end;
              pdf.add(uno);
              pdf.create().getBuffer(function(buffer) {
                resolve(buffer);
              });

            });
          })
      }

      promiseLoadPdfPlantilla = () => {
        return new Promise((resolve, reject) => {
            const pdf: PdfMakeWrapper = new PdfMakeWrapper();
            pdf.pageSize({
              width: 612,
              height: 792
            });
            pdf.pageMargins([ 60, 80, 60, 70 ]);
            new Img(this.url_href+'assets/images/formatoHeader.png').absolutePosition(35, 0).width(300).build().then( header => {
              new Img(this.url_href+'assets/images/formatoFooter.png').absolutePosition(57, 0).width(500).build().then( footer => {
                pdf.header(header);
                pdf.footer(footer);

                let textoQR="||"+this.datosPlantilla.id_registro+"|"+this.datosPlantilla.expediente+"|"+this.datosPlantilla.expProCon+"|"+
                this.datosPlantilla.expEntDep+'|'+this.datosPlantilla.expFecha.toString()+'|'+
                this.datosPlantilla.usuario[0].firmante+'|'+this.datosPlantilla.usuario[0].nombre_firma+'|'+this.datosPlantilla.usuario[0].rfc_firma+"|"+this.datosPlantilla.usuario[0].codigosha+'.fir|';

                pdf.add(new Txt(`EXPEDIENTE: ${this.datosPlantilla.expediente}`).alignment('right').font('monserrat').bold().fontSize(12).end);
                pdf.add(new Txt(`PROVEEDOR O CONTRATISTA: ${this.datosPlantilla.expProCon}`).alignment('right').font('monserrat').bold().fontSize(12).end);
                pdf.add(new Txt(`ENTIDAD O DEPENDENCIA: ${this.datosPlantilla.expEntDep}`).alignment('right').font('monserrat').bold().fontSize(12).end);
                pdf.add(new Txt(`FECHA: ${this.datosPlantilla.expFecha.toString().substring(0,10)}\n`).alignment('right').font('monserrat').bold().fontSize(12).end);
                pdf.add(new Txt(` `).end);
                pdf.add(new Txt(` `).end);
                pdf.add(new Txt(`${this.datosPlantilla.textoDoc}`).alignment('justify').font('monserrat').fontSize(12).end);
                pdf.add(new Txt(` `).end);
                pdf.add(new Txt(` `).end);
                let datoTemp:number = this.datosPlantilla.usuario[0].involucrados.length;
                var lineasf1 =[],lineasf2 =[];
                if (datoTemp == 0) {
                  pdf.add(new Txt( `POR ${this.datosPlantilla.usuario[0].firmante}` ).alignment('center').font('monserrat').end);
                  pdf.add(new Txt( ' ' ).alignment('center').end);
                  pdf.add(new Txt( ` ${this.datosPlantilla.usuario[0].codigosha}.fir ` ).decoration('underline').alignment('center').font('monserrat').end);
                  pdf.add(new Txt( this.datosPlantilla.usuario[0].nombre_firma ).alignment('center').font('monserrat').end);
                  pdf.add(new Txt( this.datosPlantilla.usuario[0].rfc_firma ).alignment('center').font('monserrat').end);
                  pdf.add(new Txt( ' ' ).end);
                } else {
                  lineasf1.push([{ text: ' ' }]);
                  lineasf1.push([{ text: `POR ${this.datosPlantilla.usuario[0].firmante}`, alignment: 'center' }]);
                  lineasf1.push([{ text: ' ' }]);
                  lineasf1.push([{ text: ` ${this.datosPlantilla.usuario[0].codigosha}.fir `, decoration: 'underline', alignment: 'center', fontSize: 9 }]);
                  lineasf1.push([{ text: this.datosPlantilla.usuario[0].nombre_firma, alignment: 'center' }]);
                  lineasf1.push([{ text: this.datosPlantilla.usuario[0].rfc_firma, alignment: 'center' }]);
                  lineasf1.push([{ text: ' ' }]);
                  for (let j = 0; j < this.datosPlantilla.usuario[0].involucrados.length; j++) {
                    textoQR=textoQR+this.datosPlantilla.usuario[0].involucrados[j].firmante+'|'+this.datosPlantilla.usuario[0].involucrados[j].nombre_firma+'|'+
                    this.datosPlantilla.usuario[0].involucrados[j].rfc_firma+"|"+this.datosPlantilla.usuario[0].involucrados[j].codigosha+'.fir|';

                    if(j%2==0){
                      lineasf2.push([{ text: ' ' }]);
                      lineasf2.push([{ text: `POR ${this.datosPlantilla.usuario[0].involucrados[j].firmante}`, alignment: 'center' }]);
                      lineasf2.push([{ text: ' ' }]);
                      lineasf2.push([{ text: ` ${this.datosPlantilla.usuario[0].involucrados[j].codigosha}.fir `, decoration: 'underline', alignment: 'center', fontSize: 9 }]);
                      lineasf2.push([{ text: this.datosPlantilla.usuario[0].involucrados[j].nombre_firma, alignment: 'center' }]);
                      lineasf2.push([{ text: this.datosPlantilla.usuario[0].involucrados[j].rfc_firma, alignment: 'center' }]);
                      lineasf2.push([{ text: ' ' }]);
                    } else {
                      lineasf1.push([{ text: ' ' }]);
                      lineasf1.push([{ text: `POR ${this.datosPlantilla.usuario[0].involucrados[j].firmante}`, alignment: 'center' }]);
                      lineasf1.push([{ text: ' ' }]);
                      lineasf1.push([{ text: ` ${this.datosPlantilla.usuario[0].involucrados[j].codigosha}.fir `, decoration: 'underline', alignment: 'center', fontSize: 9 }]);
                      lineasf1.push([{ text: this.datosPlantilla.usuario[0].involucrados[j].nombre_firma, alignment: 'center' }]);
                      lineasf1.push([{ text: this.datosPlantilla.usuario[0].involucrados[j].rfc_firma, alignment: 'center' }]);
                      lineasf1.push([{ text: ' ' }]);
                    }
                  }
                }
                
                if (Array.isArray(this.datosPlantilla.usuario[0].involucrados) && this.datosPlantilla.usuario[0].involucrados.length ){
                  var tabla =new Table([
                    [ lineasf1, lineasf2],
                  ]).widths([ '50%', '50%']).layout('noBorders').alignment('center').font('monserrat').end;
                  pdf.add(tabla);
                }
                textoQR=textoQR+"|";
                let uno =new QR(this.stringUrlbase+'/revisionAcuses?nsession='+encodeURIComponent(this.encriptaText(textoQR))).fit(80).absolutePosition(500, 650).end;
                pdf.add(uno);
                pdf.create().getBuffer(function(buffer) {
                  resolve(buffer);
                });
              });
            });
          })
      }


      getArchivo(id_registro)  {
        return new Promise(resolve => {
          this.registroService.getArchivobyId(id_registro).subscribe(
              async (response) => {
                if (response.archivo!="false"){
                  const existingPdfBytes = Buffer.from(response.archivo, 'base64');
                  try{//en archivos PDF encriptados no se puede cambiar la letra
                    const pdfDoc = await PDFDocument.load(existingPdfBytes)
                    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
                    let acuse =await this.cargaRegistroV(id_registro,null);
                    if(acuse.hasOwnProperty("severity")){
                      resolve({severity:'error', summary:acuse['summary'], detail:acuse['detail']});
                    }else{
                      const pdf = await PDFDocument.load(<ArrayBuffer>acuse);
                      const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());
                      copiedPages.forEach((page) => {
                        pdfDoc.addPage(page);
                      });
                      resolve(await pdfDoc.save());
                    }
                  }catch(e){
                    resolve(existingPdfBytes);
                  }
      
                }else{
                  resolve({severity:'error', summary:'Error al bajar el archivo:', detail:"No existe el archivo registrado en el repositorio"});
                }
              },
              error =>{
                resolve({severity:'error', summary:'Error al cargar el registro de firma en la BD:', detail:error});
              }
          );    
        });
      } 



  getPlantilla(id_registro)  {
    return new Promise(resolve => {
      this.registroService.getPlantillabyId(id_registro).subscribe(
          async (response) => {
            if (response.archivo!="false"){
              let acuse1 =await this.cargaPlantilla(id_registro,null);
              let acuse2 =await this.cargaRegistroV(id_registro,null);
              const existingPdfBytes = Buffer.from(acuse1, 'base64');
              try{//en archivos PDF encriptados no se puede cambiar la letra
                const pdfDoc = await PDFDocument.load(existingPdfBytes)
                if(acuse1.hasOwnProperty("severity")){
                  resolve({severity:'error', summary:acuse1['summary'], detail:acuse1['detail']});
                }else{
                  if(acuse2.hasOwnProperty("severity")){
                    resolve({severity:'error', summary:acuse2['summary'], detail:acuse2['detail']});
                  }else{
                    const pdf = await PDFDocument.load(<ArrayBuffer>acuse2);
                    const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());
                    copiedPages.forEach((page) => {
                      pdfDoc.addPage(page);
                    });
                    resolve(await pdfDoc.save());
                  }
                }
              }catch(e){
                resolve(existingPdfBytes);
              }

            }else{
              resolve({severity:'error', summary:'Error al bajar el archivo:', detail:"No existe el archivo registrado en el repositorio"});
            }
          },
          error =>{
            resolve({severity:'error', summary:'Error al cargar el registro de firma en la BD:', detail:error});
          }
      );    
    });
  } 
    


  public encriptaText( texto){
      //this.logger.debug("Texto original: "+texto)
      var base64Coded = window.btoa(texto);
      //this.logger.debug("Base 64: "+base64Coded)
      var keyWords = CryptoJS.enc.Utf8.parse(this.keySecrets);
      var ivWords = CryptoJS.lib.WordArray.create([0, 0]);
      var encrypted = CryptoJS.DES.encrypt(texto, keyWords, { iv: ivWords});
      //this.logger.debug("texto encriptado sin base: "+encrypted.ciphertext);
      //this.logger.debug("texto encriptado: "+CryptoJS.enc.Base64.stringify(encrypted.ciphertext));
      return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
  }

  public dencriptaText( textEncrip ){
    //this.logger.debug("Encriptado: "+textEncrip)
    var base64Decoded = CryptoJS.enc.Base64.parse(textEncrip);
    //this.logger.debug("texto Sin base 64: "+base64Decoded)


    var keyWords = CryptoJS.enc.Utf8.parse(this.keySecrets);
    var ivWords = CryptoJS.lib.WordArray.create([0, 0]);

    var decrypted = CryptoJS.DES.decrypt({ciphertext: base64Decoded}, keyWords, { iv: ivWords });
    //this.logger.debug("decrip64: "+decrypted)
    return decrypted.toString(CryptoJS.enc.Utf8);
    
  }


  encryptDesCbcPkcs7Padding(message, key) {
    var keyWords = CryptoJS.enc.Utf8.parse(key);
    var ivWords = CryptoJS.lib.WordArray.create([0, 0]);
    var encrypted = CryptoJS.DES.encrypt(message, keyWords, { iv: ivWords});
  
    return encrypted;//.toString(CryptoJS.enc.Utf8);
}


decryptDesCbcPkcs7Padding(message, key) {
    var keyWords = CryptoJS.enc.Utf8.parse(key);
    var ivWords = CryptoJS.lib.WordArray.create([0, 0]);

    var decrypted = CryptoJS.DES.decrypt({ciphertext: message}, keyWords, { iv: ivWords });
    //this.logger.debug("decrip ", decrypted);
    return decrypted.toString(CryptoJS.enc.Utf8);
}

actualizaEstado(id_registro, estado){
  return new Promise((resolve, reject) => {
    this.registroService.updateEstado(id_registro,estado)
    .pipe(first())
    .subscribe(data => {
      if(data==1){
        resolve(true);
      }else{
        resolve({severity:'error', summary:'Actualización del registro', detail:'No se pudo actualizar el registro de la firma, '+JSON.stringify(data)});
      }
    },
    error => {
      resolve({severity:'error', summary:'Actualización del registro', detail:'No se pudo actualizar el registro de la firma, '+error.message});
    });
  });
}


}