import { Component, OnInit,AfterViewInit,ViewChild, NgZone, ChangeDetectorRef } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { Router, NavigationStart,NavigationEnd } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { RegistroService } from '../../../services/registro.service';
import { Registro } from '../../../model/registro';
import { Involucrado } from '../../../model/involucrados';
import { CommonsShared } from '../../../commons/commons.shared';
import { NGXLogger } from 'ngx-logger';



declare var cambiaDatosFE: any;


@Component({
  selector: 'app-revision-acuses',
  templateUrl: './revision-acuses.component.html',
  styleUrls: ['./revision-acuses.component.css']
})
export class RevisionAcusesComponent implements OnInit, AfterViewInit {

  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand;
  datosReunion: Registro = new Registro();
  pageTitle = 'Firma electrónica de archivos';
  title = 'FIRMA |Conciliaciones';
  nombre_sp='';
  navOpen:     boolean;
  minHeight:   string;  
  loading = false;
  msgs = [];
  fecha: Date;
  archivo: any;
  dicionarFir = false;
  columnas = [
    { field: 'nombre_firma', header: 'Nombre del firmante' },
    { field: 'rfc_firma', header: 'RFC del firmante' },
    { field: 'fecha', header: 'Fecha de Firma' },
    { field: 'transaccion', header: 'Transacción' },
    { field: 'num_certificado', header: 'Certificado' }
  ];
  columnasF = [
    { field: 'nombre_firma', header: 'Nombre del firmante' },
    { field: 'rfc_firma', header: 'RFC del firmante' },
    { field: 'fecha', header: 'Fecha de Firma' },
    { field: 'transaccion', header: 'Transacción' },
    { field: 'num_certificado', header: 'Certificado' },
    { field: 'firmante', header: 'Firmante' }
  ];
  first = 0;
  rows = 10;
  veVisor=false;
  habilitado=false;
  plantilla: any={ id_registro:0,id_plantilla:0,expediente:"",expProCon:"",expEntDep:"",expFecha:"",textoDoc:""};

  constructor(private ngZone: NgZone,private registroService: RegistroService, 
    private formBuilder: RxFormBuilder, platformLocation: PlatformLocation, private logger: NGXLogger, 
    private cdRef: ChangeDetectorRef,private router: Router,private commons: CommonsShared ) {
  }

  ngOnInit(): void {
    this.msgs=[];
    try{
      let sidRegistro = this.commons.dencriptaText(this.commons.currentnReunionValue);
      let datos  =  sidRegistro.split("|");
      //this.logger.debug("datos: "+JSON.stringify(datos));
      if (datos[2]){
        this.cargaReunion(parseInt(datos[2]));
      }else{
        throw "No se recibieron los parametros necesarios";
      }
    }catch(e){
      this.msgs.push({severity:'error', summary:'Error en la aplicación', detail:'Ocurrio un error:'+e});
    }

  }
  async cargaReunion(idRegistro){
    const data=await this.commons.cargaRegistro(idRegistro);
    if(data.hasOwnProperty("severity")){
      //this.logger.error("Error ");
      this.msgs.push(data);
    }else{
      this.datosReunion.rfc_firma=data["rfc_firma"];
      this.datosReunion.folio=data["folio"];
      this.datosReunion.num_certificado=data["num_certificado"];
      this.datosReunion.transaccion=data["transaccion"];
      this.datosReunion.codigosha=data["codigoSha"];
      this.datosReunion.id_registro=data["id_registro"];
      this.datosReunion.nombre_firma=data["nombre_firma"];
      this.datosReunion.nombre_reunion=data["nombre_reunion"];
      this.datosReunion.codigoshas_inicial=data["codigoshas_inicial"];
      this.datosReunion.fecha=data["fecha"];
      this.datosReunion.nombre_archivo=data["nombre_archivo"];
      this.datosReunion.tamano=data["tamano"];
      this.datosReunion.ruta=data["ruta"];
      this.datosReunion.plantilla=data['plantilla'];
      this.datosReunion.firmante=data['firmante'];
      this.datosReunion.estado=data['estado'];
      if (this.datosReunion.plantilla){
        this.plantilla=await this.commons.getPlantillaById(idRegistro);
      }

      let conciliador=  new Involucrado();
      conciliador.id_registro=idRegistro;
      conciliador.id_involucrado=0;
      conciliador.rfc_firma=this.datosReunion.rfc_firma;
      conciliador.nombre_firma=this.datosReunion.nombre_firma;
      conciliador.codigoshas_inicial= this.datosReunion.codigoshas_inicial;
      conciliador.fecha= this.datosReunion.fecha;
      conciliador.folio= this.datosReunion.folio;
      conciliador.num_certificado= this.datosReunion.num_certificado;
      conciliador.transaccion= this.datosReunion.transaccion;
      conciliador.codigosha=this.datosReunion.codigosha;
      conciliador.firmante= this.datosReunion.firmante;
      this.datosReunion.involucrados = [conciliador];
      this.datosReunion.involucrados.push(data["involucrados"]);
      this.habilitado=true;
      //this.logger.debug("data: "+JSON.stringify(data));
      //this.logger.debug("reunion: "+JSON.stringify(this.datosReunion));
    }
  }


  async  cargaAcuse(){
    this.loading = true;
    this.msgs =[];
    const uu=await this.commons.cargaRegistroV(this.datosReunion.id_registro,this.pdfViewerOnDemand);
    if(uu.hasOwnProperty("severity")){
      this.msgs.push(uu);
    }
    this.loading = false;
    this.veVisor =true;
  }

  async  cargaPlantilla(){
    this.loading = true;
    this.msgs =[];
    const uu=await this.commons.cargaPlantilla(this.datosReunion.id_registro,this.pdfViewerOnDemand);
    if(uu.hasOwnProperty("severity")){
      this.msgs.push(uu);
    }
    this.loading = false;
    this.veVisor =true;
  }

  async  getArchivo(){
    this.loading = true;
    this.msgs =[];
    const uu=await this.commons.getArchivo(this.datosReunion.id_registro);
    if(uu.hasOwnProperty("severity")){
      this.msgs.push(uu);
    }else{
      this.pdfViewerOnDemand.pdfSrc= uu;
      this.pdfViewerOnDemand.refresh();      
      this.veVisor =true;
    }
    this.loading = false;
  }

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  cierraVisor(){
    this.veVisor=false;
  }

  ngAfterViewInit () {
    this.cdRef.detectChanges();
  }

}