import { Component, OnInit, ChangeDetectorRef,Output,EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { first } from 'rxjs/operators';
import { LoginService } from '../../../services/login.service';
import { NGXLogger } from 'ngx-logger';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  pageTitle = 'Firma de conciliaciones';
  
  public readonly siteKey = '6LfiTooUAAAAAIHZbaMgNXG4n-KuMQolYrB7fxCR';
  
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;  
  public captchaIsLoaded = false;
  public showCaptcha = true;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'es';
  public type: 'image' | 'audio' = 'image';
  
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  //md5 = require('md5');
  @Output() messageEvent = new EventEmitter<string>();

  
  constructor(private title: Title,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private cdRef:ChangeDetectorRef,
        private loginService: LoginService,private logger: NGXLogger) { }

  ngOnInit() {

    //this.logger.debug(this.md5('message')+"=md5");

      this.title.setTitle(this.pageTitle);
       this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // cierra cesion
        this.loginService.logout();

        // optiene la ruta de inicio '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/firma';
        this.cdRef.detectChanges();
  }

 
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        this.loginService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.router.navigate([this.returnUrl]);
//                    this.messageEvent.emit("refrescar");
                    this.loading = false;
                },
                error => {
                    if(error.graphQLErrors && error.graphQLErrors.length > 0){
                        this.error = error.graphQLErrors[0].message;
                    }else
                        this.error = error.message;
                    this.loading = false;
                    this.handleReset();
                });
    }

    limpiar (event: Event) {
       this.f.username.setValue("");
       this.f.password.setValue("");
       this.submitted = false;
       this.error='';
       this.handleReset();
    }


    handleReset(): void {
       this.captchaSuccess = false;
       this.captchaResponse = undefined;
       this.captchaIsExpired = false;
        this.showCaptcha = false;
        setTimeout(() => {
             this.showCaptcha = true;
        });          
       
     }

    handleExpire(): void {
        this.captchaSuccess = false;
        this.captchaIsExpired = true;
    }

    handleLoad(): void {
        this.captchaIsLoaded = true;
        this.captchaIsExpired = false;
    }

    handleSuccess(captchaResponse: string): void {
        this.captchaSuccess = true;
        this.captchaResponse = captchaResponse;
        this.captchaIsExpired = false;
    }
    



    
}
