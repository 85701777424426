import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: true,
  APIEndpointBack: window["env"]["APIENDPOINTBACK"] || "https://dgti-ees-firma-conciliaciones-back-end.k8s.funcionpublica.gob.mx/graphql",
  APIFirma: window["env"]["APIFIRMA"] || "https://firma.apps.funcionpublica.gob.mx/assets/plugin/sfpsign.js",
  APIFirmaCliente_id: window["env"]["APIFIRMACLIENTE_ID"] || "21129",
  APIFirmaSecretKey: window["env"]["APIFIRMASECRETKEY"] || "DIFHEOALFHUDEJHXZRLJF",
  apiLoggerUrl: window["env"]["APILOGGER"] || 'http://api.myservice.com/api/', //
  logLevel: window["env"]["LOGLEVEL"] || NgxLoggerLevel.OFF,
  serverLogLevel: window["env"]["SERVERLOGLEVEL"] || NgxLoggerLevel.ERROR
};
