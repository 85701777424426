import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map } from 'rxjs/operators';
import { Usuario } from '../model/usuario';


@Injectable({ providedIn: 'root' })
export class UsuarioService {

    constructor(private apollo: Apollo) {

    }

    getUsuariobyId(id_usuario) {
        return this.apollo.watchQuery<any>({
            query: gql`
            query getUsuariobyId($identificador: Int!) 
            { 
                getUsuariobyId(identificador: $identificador){
                    identificador
                    clave
                    id_tipo_usuario
                    nombre
                    ape_paterno
                    ape_materno
                    rfc
                    correo
                    fecha_alta
            }
            `,
            variables: {
                identificador: id_usuario,
            },
            fetchPolicy: 'no-cache',
        }).valueChanges.pipe(
            map(({ data }) => {
                if (data) {
                    return data.getUsuariobyId;
                }
                return null;
            })

        );
    }


   addUsuario(usuario: Usuario) {
        return this.apollo.mutate({
            mutation: gql`
            mutation addUsuario(
                $id_tipo_usuario: Int!,
                $nombre: String!,
                $ape_paterno: String,
                $ape_materno: String,
                $rfc: String!,
                $correo: String!,
                $status: Int!,
                $clave: String,
                $contrasena: String,
                $fecha_alta: Date!
            ){
                addUsuario(
                    id_tipo_usuario: $id_tipo_usuario,
                    nombre: $nombre,
                    ape_paterno: $ape_paterno,
                    ape_materno: $ape_materno,
                    rfc: $rfc,
                    correo: $correo,
                    status: $status,
                    clave: $clave,
                    contrasena: $contrasena,
                    fecha_alta: $fecha_alta
                )
            }                
            `,
            variables: {
                id_tipo_usuario: usuario.id_tipo_usuario,
                nombre: usuario.nombre,
                ape_paterno: usuario.ape_paterno,
                ape_materno: usuario.ape_materno,
                rfc: usuario.rfc,
                correo: usuario.correo,
                status: usuario.status,
                clave: usuario.clave,
                contrasena: usuario.contrasena,
                fecha_alta: usuario.fecha_alta
            },
             fetchPolicy: 'no-cache',
        }).pipe(
            map(({ data }) => {
                if (data) {
                    return data;
                }
                return null;
            })

        );
    }

}