import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from '../../../services/usuario.service';
import { Usuario } from '../../../model/usuario';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { LoginService } from '../../../services/login.service';
import { MessageService } from 'primeng/api';
import { Message } from 'primeng/api';



@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss'],
  styles: [`
  :host ::ng-deep .custom-toast .ui-toast-message {
      color: #000000;
      background: #ffffff;
  }

  :host ::ng-deep .custom-toast .ui-toast-close-icon {
      color: #000000;
  }
`],
  providers: [MessageService]
})

export class UsuarioComponent implements OnInit, AfterViewInit {

  pageTitle = 'Alta de usuarios';
  usuarioForm: FormGroup;
  tit_tipo = "";
  usuario: any;
  loading = false;
  isNuevo = false;
  submittedUsuario = false;
  guardoRegistro: boolean = false;
  error = '';
  msgs: Message[] = [];
  currentUser: any;

  constructor(private ngZone: NgZone, private cdRef: ChangeDetectorRef, private usuarioService: UsuarioService
    , private loginService: LoginService, private messageService: MessageService,private router: Router) {
    this.usuario = this.loginService.currentUserValue;



  }

  ngOnInit() {
    this.isNuevo = false;
    this.guardoRegistro = false;
    this.usuarioForm = new FormGroup({
      identificador: new FormControl(''),
      nombre: new FormControl({ value: '', disabled: true }, Validators.required),
      ape_paterno: new FormControl({ value: '', disabled: true }, Validators.required),
      ape_materno: new FormControl({ value: '', disabled: true }),
      rfc: new FormControl('', Validators.required),
      correo: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.email]),
      clave: new FormControl({ value: '', disabled: true }, Validators.required),
      contrasena: new FormControl({ value: '', disabled: true }, [Validators.required]),
      contrasenaRepeat: new FormControl({ value: '', disabled: true }, [Validators.required])
    });
    this.tit_tipo = "Nuevo Usuario";
    this.limpiaRegistro();
  }



  get f() { return this.usuarioForm.controls; }

  ngAfterViewInit() {

  }



  onSubmitUsuario($event) {

    $event.preventDefault();
    this.guardoRegistro = false;
    this.error = "";
    this.loading = true;
    this.submittedUsuario = true;

    if (this.usuarioForm.invalid) {
      /*      for (const name in this.usuarioForm.controls) {
              if (this.usuarioForm.controls[name].invalid) {
                this.messageService.add({severity:'error', summary: 'Campo requerido', detail: name});
              }
            }*/
      this.loading = false;
      return;
    }

    if (this.f.contrasena.value !== this.f.contrasenaRepeat.value) {
      this.error = 'La contraseña no coincide';
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "La contraseña no coincide" });
      this.loading = false;
      return;
    }




    if (this.isNuevo) {
      let usuario = new Usuario;
      usuario.identificador = this.f.identificador.value;
      usuario.id_tipo_usuario = 2;
      usuario.nombre = this.f.nombre.value;
      usuario.ape_paterno = this.f.ape_paterno.value;
      usuario.ape_materno = this.f.ape_materno.value;
      usuario.rfc = this.f.rfc.value;
      usuario.correo = this.f.correo.value;
      usuario.clave = this.f.clave.value;
      usuario.contrasena = this.f.contrasena.value;
      usuario.status = 0;
      usuario.fecha_alta = new Date();

      this.usuarioService.addUsuario(usuario)
        .pipe(first())
        .subscribe(
          data => {
            this.loading = false;
            this.submittedUsuario = false;
            if (data.hasOwnProperty('addUsuario')) {
              this.messageService.add({ severity: 'info', summary: 'Exito', detail: "Se dio de alta al usuario" });
              this.guardoRegistro = true;
              this.isNuevo = false;
              this.enabledInputs(false);
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: "No se pudo dar de alta el usuario" });
            }
          },
          error => {
            if (error.graphQLErrors && error.graphQLErrors.length > 0) {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: error.graphQLErrors[0].message });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: error.message });
            }
            this.loading = false;
          });

    }
  }


  limpiaRegistro() {
    this.isNuevo = true;
    this.guardoRegistro = false;
    this.submittedUsuario = false;
    this.error = "";
    this.f.nombre.setValue("");
    this.f.ape_paterno.setValue("");
    this.f.ape_materno.setValue("");
    this.f.correo.setValue("");
    this.f.rfc.setValue("");
    this.f.clave.setValue("");
    this.f.contrasena.setValue("");
    this.f.contrasenaRepeat.setValue("");

    this.enabledInputs(true);

  }

  enabledInputs(ops) {
    if (ops) {
      this.f.nombre.enable();
      this.f.ape_paterno.enable();
      this.f.ape_materno.enable();
      this.f.correo.enable();
      this.f.rfc.enable();
      this.f.clave.enable();
      this.f.contrasena.enable();
      this.f.contrasenaRepeat.enable();
    } else {
      this.f.nombre.disable();
      this.f.ape_paterno.disable();
      this.f.ape_materno.disable();
      this.f.correo.disable();
      this.f.rfc.disable();
      this.f.clave.disable();
      this.f.contrasena.disable();
      this.f.contrasenaRepeat.disable();
    }
  }

  cierraSession() {
    this.loginService.logout();
    this.router.navigate(['/login']);
    this.currentUser = this.loginService.currentUserValue;

  }

  salirdeBusqueda(){
    this.limpiaRegistro();
    this.router.navigate(['firma']);   
  }

}


