<div id="formulario"  class="formulario" >
  <form [formGroup]="usuarioForm" (ngSubmit)="onSubmitUsuario($event)">
    <div class="row flex-xl-nowrap">
      <div class="col-12 col-md-3 col-xl-1 bd-sidebar"> </div>
      <div class="col-12 col-md-12 col-xl-12 py-md-3 pl-md-5 bd-content bg-white">
        <h1 class="bd-title" id="content">{{pageTitle}}</h1>
        <div style="text-align: right; width: 100%;">
          <span *ngIf="currentUser">{{currentUser.nombre+' '+ currentUser.ape_paterno+' '+currentUser.ape_materno}}</span>
        </div>
        <div style="text-align: right; width: 100%;">
          <a [routerLink]="['/login']" class="navbar-text" *ngIf="currentUser!==null" (click)="cierraSession()">
            Cerrar sesión
          </a>
        </div>
        <p-messages [(value)]="msgs"></p-messages>

        <div class="col-12 col-md-11 col-xl-12 py-md-3 pl-md-4 ">
          <div class="bd-example" style="width: 700px;">

            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label for="inputrfc"><span class="titleName">* </span>RFC</label>
                  <input type="text" maxlength="13" oninput="this.value = this.value.toUpperCase()" class="form-control" 
                    id="inputrfc" placeholder="RFC" formControlName="rfc"
                    [ngClass]="{ 'is-invalid': submittedUsuario && f.rfc.errors }">
                  <div *ngIf="submittedUsuario && f.rfc.errors" class="invalid-feedback">
                    <div *ngIf="f.rfc.errors.required">RFC es requerido</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label for="inputnombre"><span class="titleName">* </span>Nombre(s)</label>
                  <input type="text" class="form-control" id="inputnombre" placeholder="Nombre(s)" 
                    formControlName="nombre" [ngClass]="{ 'is-invalid': submittedUsuario && f.nombre.errors }">
                  <div *ngIf="submittedUsuario && f.nombre.errors" class="invalid-feedback">
                    <div *ngIf="f.nombre.errors.required">Nombre(s) es requerido</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row" >
              <div class="col">
                <div class="form-group">
                  <label for="inputapepat"><span class="titleName">* </span>Apellido paterno</label>
                  <input type="text" class="form-control" id="inputapepat" placeholder="Apellido paterno" 
                    formControlName="ape_paterno" [ngClass]="{ 'is-invalid': submittedUsuario && f.ape_paterno.errors }">
                  <div *ngIf="submittedUsuario && f.ape_paterno.errors" class="invalid-feedback">
                    <div *ngIf="f.ape_paterno.errors.required">Apellido paterno es requerido</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="inputapemat">Apellido materno</label>
                  <input type="text" class="form-control" id="inputapemat" placeholder="Apellido materno" 
                    formControlName="ape_materno">
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label for="inputemail"><span class="titleName">* </span>Correo Electrónico</label>
                  <input type="text" class="form-control" id="inputemail" placeholder="Correo Electrónico" 
                    formControlName="correo" [ngClass]="{ 'is-invalid': submittedUsuario && f.correo.errors }">
                  <div *ngIf="submittedUsuario && f.correo.errors" class="invalid-feedback">
                    <div *ngIf="f.correo.errors.required">Correo Electrónico es requerido</div>
                  </div>
                </div>
              </div>
            </div>

            <hr >

            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label for="inputusuario"><span class="titleName">* </span>Usuario</label>
                  <input type="text" class="form-control" id="inputusuario" placeholder="Usuario" formControlName="clave" 
                    [ngClass]="{ 'is-invalid': submittedUsuario && f.clave.errors }">
                  <div *ngIf="submittedUsuario && f.clave.errors" class="invalid-feedback">
                    <div *ngIf="f.clave.errors.required">Usuario es requerido</div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="inputpass"><span class="titleName">* </span>Contrasena</label>
                  <input type="password" class="form-control" id="inputpass" placeholder="Contrasena" 
                    formControlName="contrasena" [ngClass]="{ 'is-invalid': submittedUsuario && f.contrasena.errors }">
                  <div *ngIf="submittedUsuario && f.contrasena.errors" class="invalid-feedback">
                    <div *ngIf="f.contrasena.errors.required">Contrasena es requerido</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="inputpassre"><span class="titleName">* </span>Repita la contrasena</label>
                  <input type="password" class="form-control" id="inputpassre" placeholder="Contrasena" 
                    formControlName="contrasenaRepeat"
                    [ngClass]="{ 'is-invalid': submittedUsuario && f.contrasenaRepeat.errors }">
                  <div *ngIf="submittedUsuario && f.contrasenaRepeat.errors" class="invalid-feedback">
                    <div *ngIf="f.contrasenaRepeat.errors.required">Contraseña es requerido</div>
                  </div>
                </div>
              </div>
            </div>

            <span class="titleName">* Campos requeridos</span>
          </div>
        </div>
        
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="salirdeBusqueda()">Regresar a la firma de Archivos</button>&nbsp;
          <button type="button" class="btn btn-danger" (click)="limpiaRegistro()" *ngIf="!guardoRegistro">Limpia</button>&nbsp;
          <button type="button" class="btn btn-danger" (click)="limpiaRegistro()" *ngIf="guardoRegistro">Nuevo Usuario</button>&nbsp;
          <button type="submit" class="btn btn-success" [disabled]="loading" >Guardar</button>
        </div>
      </div>
    </div>
  </form>
</div>