<div >
  <router-outlet></router-outlet>

<!--

  <div id="Cargando" class="cargando" [hidden]="!loading">
    ....Cargando Información....
  </div>

  <div id="Error"  class="error" [hidden]="!msgs.length">
    <p-messages [(value)]="msgs"></p-messages>
  </div>  -->
</div>