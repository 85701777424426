import { NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FirmaComponent } from './pages/firma/firma.component';
import { BusquedaAcusesComponent } from './pages/busqueda-acuses/busqueda-acuses.component';
import { FirmaInvolucradoComponent } from './pages/firma-involucrado/firma-involucrado.component';
import { RevisionAcusesComponent } from './pages/revision-acuses/revision-acuses.component';
import { LoginComponent } from './pages/login/login.component';
import { UsuarioComponent } from './pages/usuario/usuario.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },{
    path: 'login',
    component: LoginComponent 
  },{
    path: 'firma',
    component: FirmaComponent, canActivate: [AuthGuard] 
  },{
    path: 'busqueda',
    component: BusquedaAcusesComponent, canActivate: [AuthGuard] 
  },{
    path: 'firmaInvolucrado',
    component: FirmaInvolucradoComponent
  },{
    path: 'altaUsuario',
    component: UsuarioComponent, canActivate: [AuthGuard] 
  },{
    path: 'revisionAcuses',
    component: RevisionAcusesComponent
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

 }
