import { Component, OnInit,AfterViewInit,ViewChild, NgZone, ChangeDetectorRef,ElementRef } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { RegistroService } from '../../../services/registro.service';
import { Registro } from '../../../model/registro';
import { Plantilla } from './../../../model/plantilla';
import { RegistroPlantilla } from '../../../model/registroPlantilla';
import { first } from 'rxjs/operators';
import { FormGroup} from '@angular/forms';
import * as $ from "jquery"
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { Location } from '@angular/common';
declare var cambiaDatosFE: any;
import { CommonsShared } from '../../../commons/commons.shared';
import { LoginService } from '../../../services/login.service';
import { environment } from './../../../environments/environment';
import { NGXLogger } from 'ngx-logger';





@Component({
  selector: 'app-firma',
  templateUrl: './firma.component.html',
  styleUrls: ['./firma.component.css']
})
export class FirmaComponent implements OnInit, AfterViewInit {

  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand;

  registroForm: FormGroup;
  datosRegisto: Registro = new Registro();
  datosRegistoPl: RegistroPlantilla = new RegistroPlantilla();
  submittedRegistro = false;
  veVisor=false;
  pageTitle = 'Firma electrónica de archivos';
  title = 'FIRMA |Conciliaciones';
  nombre_sp=''; 
  private _initWinHeight = 0;
  navOpen:     boolean;
  minHeight:   string; 
  url_href = '';
  loading = false;
  parametosFirm = {
    cadena:'Probando ando',
    rfc: 'FORJ710622N36'
    };
  paramDiv='21134,RDWRWZXHZRR2589DSFPP8,1';
  msgs = [];
  uploadedFiles: any[] = [];
  fecha: Date;
  archivo: any;
  veUrl = false;
  stringUrlbase='';
  stringUrl='';
  id_registro="";
  estado=0;
  isActivDP: boolean = false;
  lista: Array<any>;
  currentUser: any;


  constructor(private ngZone: NgZone,private registroService: RegistroService,private loginService:LoginService,private elementRef: ElementRef, 
    private formBuilder: RxFormBuilder, platformLocation: PlatformLocation,private logger: NGXLogger, 
    private cdRef: ChangeDetectorRef,private router: Router,private commons: CommonsShared, private location : Location ) {
      this.url_href =(platformLocation as any).location.href;
      this.stringUrlbase=this.location['_platformStrategy']._platformLocation.location.protocol+'//'+this.location['_platformStrategy']._platformLocation.location.host;
      this.loginService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit(): void {
    if(this.isActivDP) {
      this.datosRegistoPl = new RegistroPlantilla();
      this.registroForm = this.formBuilder.formGroup(this.datosRegistoPl);
    } else {
      this.datosRegisto = new Registro();
      this.registroForm = this.formBuilder.formGroup(this.datosRegisto);
    }
    this.lista = [
      { text: 'SELECCIONAR', value: '' },
      { text: 'CONCILIADOR', value: 'EL CONCILIADOR' },
      { text: 'EMPRESA', value: 'LA EMPRESA' },
      { text: 'ENTIDAD O DEPENDENCIA', value: 'LA ENTIDAD O DEPENDENCIA' },
      { text: 'ÓRGANO INTERNO DE CONTROL', value: 'EL ÓRGANO INTERNO DE CONTROL' },
    ];
    this.submittedRegistro = false;

    var url = "https://code.jquery.com/color/jquery.color.js";
    $.getScript( url, function() {
      $( "#sfpSignScript" ).attr("src", environment.APIFirma)
    });

    window['angularComponentReference'] = { component: this, zone: this.ngZone, loadAngularFunction: (dato) => this.angularFunctionCalled(dato), };  
    $("#sfpSignButton").hide();
    this.currentUser = this.loginService.currentUserValue;

  }

  async angularFunctionCalled(dato) {  
    //this.logger.debug("datos: "+JSON.stringify(dato));
    if (dato.respuestaValidacion!=='EL USUARIO HA CANCELADO EL PROCESO DE FIRMADO'){
      if (this.isActivDP) {
        //this.callModulosService.callsendDocumento( btoa(reader.result.toString()),this.archivo.name);
        //this.callModulosService.callsendDocumento( reader.result,this.archivo.name);

        var datosRegistoTmp = new Registro();
        var datosPlantillaTmp = new Plantilla();

        datosRegistoTmp.rfc_firma=dato.rfc;
        datosRegistoTmp.folio=dato.folio;
        datosRegistoTmp.num_certificado=dato.numeroCertificado;
        datosRegistoTmp.transaccion=dato.transaccion;
        datosRegistoTmp.codigosha=dato.codigoSha;

        datosRegistoTmp.id_registro=new Uint8Array(0);
        datosRegistoTmp.nombre_firma=this.f.nombre_firma.value;
        datosRegistoTmp.nombre_reunion=this.f.nombre_reunion.value;
        datosRegistoTmp.codigoshas_inicial=this.parametosFirm.cadena;
        datosRegistoTmp.fecha=this.fecha;
        datosRegistoTmp.nombre_archivo='plantilla.pdf';
        datosRegistoTmp.tamano=0;
        datosRegistoTmp.ruta=moment().format('YYYY');
        datosRegistoTmp.plantilla = 1;
        datosRegistoTmp.firmante = this.f.firmante.value;
        this.submittedRegistro = false;

        datosPlantillaTmp.id_plantilla = 1;
        datosPlantillaTmp.expediente = this.f.expediente.value;
        datosPlantillaTmp.expProCon = this.f.expProCon.value;
        datosPlantillaTmp.expEntDep = this.f.expEntDep.value;
        datosPlantillaTmp.textoDoc = this.f.textoDoc.value;
        datosPlantillaTmp.expFecha = this.fecha;

        this.msgs =[];

        const uu= await this.adicionaRegistroPlantilla(datosRegistoTmp, datosPlantillaTmp);
        if(uu.hasOwnProperty("severity")){
          this.msgs.push(uu);
        }else{
          this.loading = true;
          this.msgs =[];
          const uuu=await this.commons.cargaPlantilla(uu,this.pdfViewerOnDemand);
          if(uuu.hasOwnProperty("severity")){
            this.msgs.push(uu);
          }
          this.id_registro= uu.toString();
          this.loading = false;
          this.estado = 0;
          this.veVisor =true;
        }
        $( "#sfpSign" ).hide();
        this.limpiaRegistro();    
        $("#regisForm").show();
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(this.archivo);
        reader.onload = async () => {
          //this.callModulosService.callsendDocumento( btoa(reader.result.toString()),this.archivo.name);
          //this.callModulosService.callsendDocumento( reader.result,this.archivo.name);

          var datosRegistoTmp = new Registro();

          datosRegistoTmp.rfc_firma=dato.rfc;
          datosRegistoTmp.folio=dato.folio;
          datosRegistoTmp.num_certificado=dato.numeroCertificado;
          datosRegistoTmp.transaccion=dato.transaccion;
          datosRegistoTmp.codigosha=dato.codigoSha;

          datosRegistoTmp.id_registro=new Uint8Array(0);
          datosRegistoTmp.nombre_firma=this.f.nombre_firma.value;
          datosRegistoTmp.nombre_reunion=this.f.nombre_reunion.value;
          datosRegistoTmp.codigoshas_inicial=this.parametosFirm.cadena;
          datosRegistoTmp.fecha=this.fecha;
          datosRegistoTmp.nombre_archivo=this.archivo.name;
          datosRegistoTmp.tamano=this.archivo.size;
          datosRegistoTmp.ruta=moment().format('YYYY');
          datosRegistoTmp.plantilla = 0;
          datosRegistoTmp.firmante = this.f.firmante.value;
          this.submittedRegistro = false;
          //this.logger.debug('CONSOLE ALGO:', datosRegistoTmp);
          this.msgs =[];

          const uu= await this.adicionaRegistro(datosRegistoTmp);
          if(uu.hasOwnProperty("severity")){
            this.msgs.push(uu);
          }else{
            this.loading = true;
            this.msgs =[];
            const uuu=await this.commons.cargaRegistroV(uu,this.pdfViewerOnDemand);
            if(uuu.hasOwnProperty("severity")){
              this.msgs.push(uu);
            }
            this.id_registro= uu.toString();
            this.loading = false;
            this.veVisor =true;
            this.estado = 0;
          }
          $( "#sfpSign" ).hide();
          this.limpiaRegistro();    
          $("#regisForm").show();
        }
      }
    }else{
      $( "#sfpSign" ).hide();
      this.limpiaRegistro();    
      $("#regisForm").show();
     
    }
    $("#sfpSignButton").prop('disabled', false);
  } 

  get f() { return this.registroForm.controls; }

/*
  envia(){


    const reader = new FileReader();
    reader.readAsDataURL(this.archivo);
    reader.onload = async () => {
      //this.callModulosService.callsendDocumento( btoa(reader.result.toString()),this.archivo.name);
      //this.callModulosService.callsendDocumento( reader.result,this.archivo.name);

      var datosRegistoTmp = new Registro();
      datosRegistoTmp.rfc_firma='FORJ710622N36';
      datosRegistoTmp.folio='122343';
      datosRegistoTmp.num_certificado='dato.numeroCertificado';
      datosRegistoTmp.transaccion=new Uint8Array(0);
      datosRegistoTmp.codigosha='dato.codigoSha';
    
      datosRegistoTmp.id_registro=new Uint8Array(0);
      datosRegistoTmp.nombre_firma='this.f.nombre_firma.value';
      datosRegistoTmp.nombre_reunion='this.f.nombre_reunion.value';
      datosRegistoTmp.codigoshas_inicial='this.parametosFirm.cadena';
      datosRegistoTmp.fecha=this.fecha;
      datosRegistoTmp.nombre_archivo='this.archivo.name';
      datosRegistoTmp.tamano=323232;
      datosRegistoTmp.ruta=moment().format('YYYY');
      this.submittedRegistro = false;
      //this.logger.debug('registro: '+JSON.stringify(datosRegistoTmp));
    
      this.msgs =[];
    
      const uu= await this.adicionaRegistro(datosRegistoTmp);
          if(uu.hasOwnProperty("severity")){
        this.msgs.push(uu);
      }else{
        this.loading = true;
        this.msgs =[];
        const uuu=await this.commons.cargaRegistroV(uu,this.pdfViewerOnDemand);
        if(uuu.hasOwnProperty("severity")){
          this.msgs.push(uu);
        }
        this.id_registro= uu.toString();
        this.loading = false;
        this.veVisor =true;
      }
      $( "#sfpSign" ).hide();
      this.limpiaRegistro();    
      $("#regisForm").show();
    }   

}   
*/

  adicionaRegistro(datosRegistoTmp){
    
    //this.logger.debug('CONSOLE ALGO:', datosRegistoTmp);
    return new Promise((resolve, reject) => {

      this.registroService.addRegistro(datosRegistoTmp,this.archivo)
      .pipe(first())
        .subscribe(
          data => {
            this.loading = false;
            if(data.hasOwnProperty('addRegistro')){
              resolve(data["addRegistro"]);
            }else{
              resolve({severity:'error', summary:'Registro de la firma', detail:'No se pudo dar de alta el registro de esta firma, '+JSON.stringify(data)});
            }
          },
          error => {
            resolve({severity:'error', summary:'Registro de la firma', detail:'No se pudo dar de alta el registro de esta firma, '+error.message});
          });
    });
  }

  adicionaRegistroPlantilla(datosRegistoTmp, datosPlantillaTmp){
    
//    this.logger.debug('CONSOLE ALGO:', datosRegistoTmp);
    return new Promise((resolve, reject) => {

      this.registroService.addRegistroPlantilla(datosRegistoTmp)
      .pipe(first())
      .subscribe(data => {
        datosPlantillaTmp.id_registro = data['addRegistroPlantilla'];
        this.registroService.addPlantilla(datosPlantillaTmp)
        .pipe(first())
        .subscribe(
          data => {
            //this.logger.debug(data["addPlantilla"]);
          },
          error => {
            resolve({severity:'error', summary:'Registro de la firma', detail:'No se pudo dar de alta el registro de esta firma, '+error.message});
          });
        this.loading = false;
        if(data.hasOwnProperty('addRegistroPlantilla')){
          resolve(data["addRegistroPlantilla"]);
        }else{
          resolve({severity:'error', summary:'Registro de la firma', detail:'No se pudo dar de alta el registro de esta firma, '+JSON.stringify(data)});
        }
      },
      error => {
        resolve({severity:'error', summary:'Registro de la firma', detail:'No se pudo dar de alta el registro de esta firma, '+error.message});
      });
    });
  }

  limpiaRegistro(){
    let cad1 = 'Las partes del procedimiento de conciliación al rubro indicado manifiestan bajo protesta de decir verdad, que ratifican todo lo actuado en la audiencia de conciliación llevada a cabo por video conferencia, en especial los acuerdos y compromisos establecidos en la misma, el día de hoy a tantos de tantos de dos mil veinte.';
    let cad2 = 'Asimismo, ratificamos el compromiso de dar cumplimiento a dichos acuerdos y compromisos en los plazos establecidos para el efecto, atendiendo las medidas establecidas por el gobierno federal con motivo de la emergencia sanitaria para evitar la propagación del coronavirus SARS-CoV2 (COVID-19).'
    this.f.rfc_firma.setValue("");
    this.f.nombre_firma.setValue("");
    this.f.nombre_reunion.setValue("");
    this.f.firmante.setValue("");
    if (this.isActivDP) {
      this.f.expediente.setValue("");
      this.f.expProCon.setValue("");
      this.f.expEntDep.setValue("");
      this.f.expFecha.setValue("");
      this.f.textoDoc.setValue(`${cad1}\n\n${cad2}`);
    }
    this.uploadedFiles = [];
    this.submittedRegistro = false;
     this.archivo=null;
  }

  onSelectImage(event) {
    this.archivo = event[0];
    this.fecha = new Date();
  }

  cierraVisor(){
    this.limpiaRegistro(); 
    this.veVisor=false;
  }

  onRemoveImage(event) {
    $( "#sfpSign" ).hide();
    this.uploadedFiles = [];
  }

  onSubmitFirma($event){

    $event.preventDefault();
    if (this.isActivDP) {
      let parts = this.f.expFecha.value.split('-');
      this.fecha = new Date(parts[0], parts[1] - 1, parts[2]);
    } else {
      this.fecha = new Date();
    }
    this.msgs = [];
    this.submittedRegistro = true;
    
    if (this.isActivDP) {
      if (!this.registroForm.invalid) {
        if($("#sfpSignButton"). length) {
          this.parametosFirm.cadena = CryptoJS.SHA256(this.datosRegisto.rfc_firma+"|"+this.f.expediente.value+"|"+this.f.expProCon.value+"|"+this.fecha).toString(CryptoJS.enc.Hex);
          this.parametosFirm.rfc = this.datosRegisto.rfc_firma;
          cambiaDatosFE.cambiaDatos(this.parametosFirm);
          $("#regisForm").hide();
          $("#sfpSignButton").click();
        } else {
          this.msgs.push({severity:'error', summary:'Error en la aplicación', detail:'No se cargo el componente firma electrónica'});
        }
      }

    } else {
      if (!this.archivo){
        this.msgs.push({severity:'info', summary:'Falta capturar información', detail:'Capture el archivo que desea firmar'});
      } else {
        if (!this.registroForm.invalid) {
          if($("#sfpSignButton"). length){
            this.parametosFirm.cadena = CryptoJS.SHA256(this.datosRegisto.rfc_firma+"|"+this.archivo.name+"|"+this.archivo.size+"|"+this.fecha).toString(CryptoJS.enc.Hex);
            this.parametosFirm.rfc = this.datosRegisto.rfc_firma;
            cambiaDatosFE.cambiaDatos(this.parametosFirm);
            $("#regisForm").hide();
            $("#sfpSignButton").click();
            //this.logger.debug("Despues del click");
          } else {
            this.msgs.push({severity:'error', summary:'Error en la aplicación', detail:'No se cargo el componente firma electrónica'});
            //alert("No encontro el componente necesario")
          }
        }
      }
    }
    
  }

  buscaAcuse(){
    this.router.navigate(['busqueda']);    
  }

  onClickDocumento() {
    let valf = this.f.rfc_firma.value;
    let valr = this.f.nombre_firma.value;
    let valn = this.f.nombre_reunion.value;
    this.isActivDP = false;
    this.datosRegisto = new Registro();
    this.registroForm = this.formBuilder.formGroup(this.datosRegisto);
    this.f.rfc_firma.setValue(valf);
    this.f.nombre_firma.setValue(valr);
    this.f.nombre_reunion.setValue(valn);
  }

  onClickPlantilla() {
    let cad1 = 'Las partes del procedimiento de conciliación al rubro indicado manifiestan bajo protesta de decir verdad, que ratifican todo lo actuado en la audiencia de conciliación llevada a cabo por video conferencia, en especial los acuerdos y compromisos establecidos en la misma, el día de hoy a tantos de tantos de dos mil veinte.';
    let cad2 = 'Asimismo, ratificamos el compromiso de dar cumplimiento a dichos acuerdos y compromisos en los plazos establecidos para el efecto, atendiendo las medidas establecidas por el gobierno federal con motivo de la emergencia sanitaria para evitar la propagación del coronavirus SARS-CoV2 (COVID-19).'
    let valf = this.f.rfc_firma.value;
    let valr = this.f.nombre_firma.value;
    let valn = this.f.nombre_reunion.value;
    this.isActivDP = true;
    this.datosRegistoPl = new RegistroPlantilla();
    this.registroForm = this.formBuilder.formGroup(this.datosRegistoPl);
    this.f.rfc_firma.setValue(valf);
    this.f.nombre_firma.setValue(valr);
    this.f.nombre_reunion.setValue(valn);
    this.f.textoDoc.setValue(`${cad1}\n\n${cad2}`);
  }

  generaUrl(){
    this.veUrl=true;
    this.stringUrl=this.stringUrlbase+'/firmaInvolucrado?nsession='+encodeURIComponent(this.commons.encriptaText(this.id_registro));
  }  

  ngAfterViewInit () {
    const s = document.createElement('script'); 
    s.type  = 'text/javascript'; 
    s.src= environment.APIFirma; 
    s.id  = 'sfpSignScript';
    this.elementRef.nativeElement.appendChild(s);
    this.cdRef.detectChanges();
  }

  cierraSession(){
    this.loginService.logout();
    this.router.navigate(['/login']);
    this.currentUser = this.loginService.currentUserValue;

  }

  altaUsuario() {
    this.router.navigate(['/altaUsuario']);
  }

  async dejarCompartir(){
    this.loading = true;
    this.msgs =[];
    const uu=await this.commons.actualizaEstado(this.id_registro,1);
    //this.logger.debug("uu: "+JSON.stringify(uu));
    if(uu.hasOwnProperty("severity")){
      this.msgs.push(uu);
    }else{
      this.estado=1;
    }
    this.loading = false;
  }

}