<div class="row flex-xl-nowrap" [hidden]="veVisor || habilitado!=0">
  <div class="col-12 col-md-9 col-xl-12 py-md-3 pl-md-5 bd-content bg-white">
    <div class="col-12 col-md-3 col-xl-12 bd-sidebar"> </div>
    <h1 class="bd-title" id="content">Datos de la reunión</h1>

    <div class="bd-example">
      <div class="form-group">
        <label for="reunion">Nombre de la reunion</label>
        <input type="text" class="form-control" id="reunion" value="{{datosReunion.nombre_reunion}}" disabled>
      </div>
      <div class="form-group">
        <label for="conciliador">Nombre del conciliador</label>
        <input type="text" class="form-control" id="conciliador" value="{{datosReunion.nombre_firma}}" disabled>
      </div>
      <div class="form-group">
        <label for="archivo">Firmante</label>
        <input type="text" class="form-control" id="archivo" value="{{datosReunion.firmante}}" disabled>
      </div>
      <div class="form-group">
        <label for="archivo">Archivo firmado</label>
        <input type="text" class="form-control" id="archivo" value="{{datosReunion.nombre_archivo}}" disabled>
      </div>

      <div class="form-group">
        <h1 class="bd-title" id="content">Firmantes</h1>
        <div class="table-responsive">
          <p-table [columns]="columnas" [value]="datosReunion.involucrados"
            [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
            currentPageReportTemplate="Muestra {first} de {last}, Total de registros {totalRecords} ">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns">
                  {{col.header}}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of columns" style="word-wrap:break-word;">
                  <p *ngIf="col.field!='id_registro'">{{rowData[col.field]}}</p>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary">
              <button type="button" class="btn btn-success" (click)="capturaFir()" [disabled]="habilitado==1">Adicionar
                Firmante</button>&nbsp;
            </ng-template>
          </p-table>
        </div>

      </div>


      <!-- div class="modal-footer">
        <button type="button" class="btn btn-success" *ngIf="!datosReunion.plantilla" (click)="getArchivo()"
          [disabled]="!habilitado">Bajar archivo</button>&nbsp;
        <button type="button" class="btn btn-success" *ngIf="!datosReunion.plantilla" (click)="cargaRegistro()"
          [disabled]="!habilitado">Ver acuse</button>&nbsp;
        <button type="button" class="btn btn-success" *ngIf="datosReunion.plantilla" (click)="cargaPlantilla()"
          [disabled]="!habilitado">Ver plantilla</button>&nbsp;
      </div -->
    </div>
  </div>
</div>


<div class="row flex-xl-nowrap" *ngIf="habilitado==1">
  <div class="col-12 col-md-9 col-xl-12 py-md-3 pl-md-5 bd-content bg-white">
    <div class="col-12 col-md-3 col-xl-12 bd-sidebar"> </div>
    <h1 class="bd-title" id="content">La reunión ya ha sido cerrada por el conciliador</h1>
  </div>
</div>





<div id="visorPdf" class="visor" [hidden]="!veVisor">
  <ng2-pdfjs-viewer #pdfViewerOnDemand style="display: block; height: 100%;"></ng2-pdfjs-viewer>
  <button type="button" class="btn btn-danger" (click)="cierraVisor()">Cerrar acuse</button>&nbsp;
</div>

<div id="Cargando" class="cargando" [hidden]="!loading">
  ....Cargando Información....
</div>

<div id="Error" class="error" [hidden]="!msgs.length">
  <p-messages [(value)]="msgs"></p-messages>
</div>


<p-dialog header="Firma del archivo" [(visible)]="adicionarFir" modal=true [style]="{width: '50vw'}">

  <form [formGroup]="registroForm" (ngSubmit)="onSubmitFirma($event)">
    <div class="row flex-xl-nowrap">
      <div class="col-12 col-md-3 col-xl-2 bd-sidebar"> </div>
      <div class="col-12 col-md-9 col-xl-8 py-md-3 pl-md-5 bd-content bg-white">
        <div class="bd-example">
          <div class="form-group">
            <label for="validationNombre">Nombre completo del firmante</label>
            <input type="text" class="form-control" id="validationNombre" formControlName="nombre_firma"
              placeholder="Nombre del Servidor Público" required
              [ngClass]="{ 'is-invalid': submittedRegistro && f.nombre_firma.errors }">
            <div *ngIf=" submittedRegistro && f.nombre_firma.errors" class="invalid-feedback">
              <div *ngIf="f.nombre_firma.errors.required">Nombre del firmante</div>
            </div>
          </div>
          <div class="form-group">
            <label for="validationRFC">RFC</label>
            <input type="text" class="form-control" id="validationRFC" formControlName="rfc_firma"
              placeholder="RFC del Servidor Público" required
              [ngClass]="{ 'is-invalid': submittedRegistro &&  f.rfc_firma.errors }"
              oninput="this.value = this.value.toUpperCase()">
            <div *ngIf="submittedRegistro &&  f.rfc_firma.errors" class="invalid-feedback">
              <div *ngIf="f.rfc_firma.errors.required">RFC del servidor público</div>
            </div>
          </div>
          <div class="form-group">
            <label for="validationFirmante">Firmante</label>
            <select class="form-control" id="validationFirmante" name="miselect" formControlName="firmante" required
              [ngClass]="{ 'is-invalid': submittedRegistro &&  f.firmante.errors }"
              oninput="this.value = this.value.toUpperCase()">
              <option [value]="item.value.toUpperCase()" *ngFor="let item of lista">{{item.text}}</option>
            </select>
            <div *ngIf="submittedRegistro &&  f.firmante.errors" class="invalid-feedback">
              <div *ngIf="f.firmante.errors.required">Selecciona Firmante</div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="limpiaRegistro()">Limpiar</button>&nbsp;
          <button type="button" class="btn btn-success" (click)="cierraAgrega()">Cancelar</button>&nbsp;
          <button type="submit" class="btn btn-success">Firmar</button>
        </div>
      </div>

    </div>
  </form>

</p-dialog>