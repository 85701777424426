import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map } from 'rxjs/operators';
import { Registro } from '../model/registro';
import { Involucrado } from '../model/involucrados';
import { Plantilla } from '../model/plantilla';
import { NGXLogger } from 'ngx-logger';


@Injectable({ providedIn: 'root' })
export class RegistroService {

    constructor(private http: HttpClient,private apollo: Apollo, private logger: NGXLogger) {

    }    

    getRegistrobyId(id_registro) {
        return this.apollo.watchQuery<any>({
            query: gql`
              query getRegistrobyId($id_registro: Int!)
            { 
                getRegistrobyId(id_registro: $id_registro) {
                    id_registro
                    rfc_firma
                    nombre_firma
                    nombre_reunion
                    codigoshas_inicial
                    fecha
                    nombre_archivo
                    tamano
                    ruta
                    folio
                    num_certificado
                    transaccion
                    codigosha
                    firmante
                    involucrados{
                        id_registro
                        id_involucrado
                        rfc_firma
                        nombre_firma
                        codigoshas_inicial
                        fecha
                        folio
                        num_certificado
                        transaccion
                        codigosha
                        firmante
                    }
                    plantilla
                    estado
                }
            }
            `,
            variables: {
                id_registro: id_registro,
            },
            fetchPolicy: 'no-cache',
          }).valueChanges.pipe(
            map( ({data}) =>{
                if (data ){
                    return data.getRegistrobyId;
                }
                 return null;
            })

         );
    }


    getArchivobyId(id_registro) {
        return this.apollo.watchQuery<any>({
            query: gql`
              query getArchivobyId($id_registro: Int!)
            { 
                getArchivobyId(id_registro: $id_registro) {
                    nombre_archivo
                    archivo
                }
            }
            `,
            variables: {
                id_registro: id_registro,
            },
            context: {
                useMultipart: true
             },
            fetchPolicy: 'no-cache',
          }).valueChanges.pipe(
            map( ({data}) =>{
                if (data ){
                    return data.getArchivobyId;
                }
                 return null;
            })

         );
    }


    getRegistros(rfc_firma, ejercicio) {
        return this.apollo.watchQuery<any>({
            query: gql`
              query getRegistrobyRfc($rfc_firma: String!, $ejercicio: Int!)
            { 
                getRegistrobyRfc(rfc_firma: $rfc_firma, ejercicio: $ejercicio) {
                    id_registro
                    rfc_firma
                    nombre_firma
                    nombre_reunion
                    codigoshas_inicial
                    fecha
                    nombre_archivo
                    tamano
                    ruta
                    folio
                    num_certificado
                    transaccion
                    codigosha
                    firmante
                    involucrados{
                        id_registro
                       id_involucrado
                       rfc_firma
                       nombre_firma
                       codigoshas_inicial
                       fecha
                       folio
                       num_certificado
                       transaccion
                       codigosha
                       firmante
                    }
                    plantilla
                    estado
                }
            }
            `,
            variables: {
                rfc_firma: rfc_firma,
                ejercicio: ejercicio,
            },
            fetchPolicy: 'no-cache',
          }).valueChanges.pipe(
            map( ({data}) =>{
                if (data ){
                    return data.getRegistrobyRfc;
                }
                 return null;
            })

         );
    }

    getEjercicios(rfc_firma) {
        return this.apollo.watchQuery<any>({
            query: gql`
              query getElementos($rfc_firma: String!)
            { 
                getElementos(rfc_firma: $rfc_firma) {
                    label
                    value
                }
            }
            `,
            variables: {
                rfc_firma: rfc_firma
            },
            fetchPolicy: 'no-cache',
          }).valueChanges.pipe(
            map( ({data}) =>{
                if (data ){
                    return data.getElementos;
                }
                 return null;
            })

         );
    }

    getRegistroFrimabyIdRFC(id_registro,rfc) {
        return this.apollo.watchQuery<any>({
            query: gql`
              query getRegistroFirmabyIdRFC($id_registro: Int!,$rfc_firma: String!)
            { 
                getRegistroFirmabyIdRFC(id_registro: $id_registro, rfc_firma: $rfc_firma) {
                    id_registro
                    id_involucrado
                }
            }
            `,
            variables: {
                id_registro: id_registro,
                rfc_firma: rfc,
            },
            fetchPolicy: 'no-cache',
          }).valueChanges.pipe(
            map( ({data}) =>{
                if (data ){
                    return data.getRegistroFirmabyIdRFC;
                }
                 return null;
            })

         );
    }



    addRegistro(registro: Registro,archivo: File) {
        return this.apollo.mutate({
            mutation: gql`
            mutation addRegistro(
                $id_registro: Int,
                $rfc_firma: String!,
                $nombre_firma: String!,
                $nombre_reunion: String,
                $codigoshas_inicial: String,
                $fecha: Date!
                $nombre_archivo: String!,
                $tamano: Int!,
                $ruta: String!,
                $folio: String!,
                $num_certificado: String!,
                $transaccion: Int!,
                $codigosha: String!,
                $archivo: Upload!,
                $plantilla: Int,
                $firmante: String
            ){
                addRegistro(
                    id_registro: $id_registro,
                    rfc_firma: $rfc_firma,
                    nombre_firma: $nombre_firma,
                    nombre_reunion: $nombre_reunion,
                    codigoshas_inicial: $codigoshas_inicial,
                    fecha: $fecha,
                    nombre_archivo: $nombre_archivo,
                    tamano: $tamano,
                    ruta: $ruta,
                    folio: $folio,
                    num_certificado: $num_certificado,
                    transaccion: $transaccion,
                    codigosha: $codigosha,
                    archivo: $archivo,
                    plantilla: $plantilla,
                    firmante: $firmante
                )
            }
            `,
            variables: {
                id_registro: 0,
                rfc_firma: registro.rfc_firma,
                nombre_firma: registro.nombre_firma,
                nombre_reunion: registro.nombre_reunion,
                codigoshas_inicial: registro.codigoshas_inicial,
                fecha: registro.fecha,
                nombre_archivo: registro.nombre_archivo,
                tamano: registro.tamano,
                ruta: registro.ruta,
                folio: registro.folio,
                num_certificado: registro.num_certificado,
                transaccion: registro.transaccion,
                codigosha: registro.codigosha,
                archivo: archivo,
                plantilla: registro.plantilla,
                firmante: registro.firmante
              },
              context: {
                 useMultipart: true
              },
              fetchPolicy: 'no-cache',
        }).pipe(
            map( ({data}) =>{
                if (data ){
                    return data;
                }
                 return null;
            })

        );
    }

    addFirma(registro: Involucrado) {
        return this.apollo.mutate({
            mutation: gql`
            mutation addInvolucrado(
                $id_registro: Int!,
                $id_involucrado: Int,
                $rfc_firma: String!,
                $nombre_firma: String!,
                $codigoshas_inicial: String,
                $fecha: Date!
                $folio: String!,
                $num_certificado: String!,
                $transaccion: Int!,
                $codigosha: String!,
                $firmante: String
            ){
                addInvolucrado(
                    id_registro: $id_registro,
                    id_involucrado: $id_involucrado,
                    rfc_firma: $rfc_firma,
                    nombre_firma: $nombre_firma,
                    codigoshas_inicial: $codigoshas_inicial,
                    fecha: $fecha,
                    folio: $folio,
                    num_certificado: $num_certificado,
                    transaccion: $transaccion,
                    codigosha: $codigosha,
                    firmante: $firmante
                )
            }                
            `,
            variables: {
                id_registro: registro.id_registro,
                id_involucrado: 0,
                rfc_firma: registro.rfc_firma,
                nombre_firma: registro.nombre_firma,
                codigoshas_inicial: registro.codigoshas_inicial,
                fecha: registro.fecha,
                folio: registro.folio,
                num_certificado: registro.num_certificado,
                transaccion: registro.transaccion,
                codigosha: registro.codigosha,
                firmante: registro.firmante
              },
              fetchPolicy: 'no-cache',
          }).pipe(
            map( ({data}) =>{
                if (data ){
                    return data;
                }
                 return null;
            })

         );
        }

    addPlantilla(plantilla: Plantilla) {
        return this.apollo.mutate({
            mutation: gql`
            mutation addPlantilla(
                $id_registro: Int,
                $id_plantilla: Int,
                $expediente: String,
                $expProCon: String,
                $expEntDep: String,
                $expFecha: Date,
                $textoDoc: String
            ){
                addPlantilla(
                    id_registro: $id_registro,
                    id_plantilla: $id_plantilla,
                    expediente: $expediente,
                    expProCon: $expProCon,
                    expEntDep: $expEntDep,
                    expFecha: $expFecha,
                    textoDoc: $textoDoc
                )
            }                
            `,
            variables: {
                id_registro: plantilla.id_registro,
                id_plantilla: plantilla.id_plantilla,
                expediente: plantilla.expediente,
                expProCon: plantilla.expProCon,
                expEntDep: plantilla.expEntDep,
                expFecha: plantilla.expFecha,
                textoDoc: plantilla.textoDoc
            },
            context: {
                useMultipart: true
            },
            fetchPolicy: 'no-cache',
          }).pipe(
            map( ({data}) =>{
                if (data ){
                    return data;
                }
                 return null;
            })

        );
    }

    getPlantillabyId(id_registro) {
        return this.apollo.watchQuery<any>({
            query: gql`
            query getPlantillabyId($id_registro: Int!) {
                getPlantillabyId(id_registro: $id_registro) {
                    id_registro
                    id_plantilla
                    expediente
                    expProCon
                    expEntDep
                    expFecha
                    textoDoc
                    usuario {
                        id_registro
                        nombre_firma
                        rfc_firma
                        codigosha
                        fecha
                        firmante
                        involucrados {
                            rfc_firma
                            nombre_firma
                            codigosha
                            fecha
                            firmante
                        }
                    }
                }
            }
            `,
            variables: {
                id_registro: id_registro,
            },
            context: {
                useMultipart: true
             },
            fetchPolicy: 'no-cache',
          }).valueChanges.pipe(
            map( ({data}) =>{
                //this.logger.debug(JSON.stringify(data));
                if (data ){
                    return data.getPlantillabyId;
                }
                 return null;
            })

         );
    }
    
    addRegistroPlantilla(registro: Registro) {
        return this.apollo.mutate({
            mutation: gql`
            mutation addRegistroPlantilla(
                $id_registro: Int,
                $rfc_firma: String!,
                $nombre_firma: String!,
                $nombre_reunion: String,
                $codigoshas_inicial: String,
                $fecha: Date!
                $nombre_archivo: String!,
                $tamano: Int!,
                $ruta: String!,
                $folio: String!,
                $num_certificado: String!,
                $transaccion: Int!,
                $codigosha: String!,
                $plantilla: Int,
                $firmante: String
            ){
                addRegistroPlantilla(
                    id_registro: $id_registro,
                    rfc_firma: $rfc_firma,
                    nombre_firma: $nombre_firma,
                    nombre_reunion: $nombre_reunion,
                    codigoshas_inicial: $codigoshas_inicial,
                    fecha: $fecha,
                    nombre_archivo: $nombre_archivo,
                    tamano: $tamano,
                    ruta: $ruta,
                    folio: $folio,
                    num_certificado: $num_certificado,
                    transaccion: $transaccion,
                    codigosha: $codigosha,
                    plantilla: $plantilla,
                    firmante: $firmante
                )
            }
            `,
            variables: {
                id_registro: 0,
                rfc_firma: registro.rfc_firma,
                nombre_firma: registro.nombre_firma,
                nombre_reunion: registro.nombre_reunion,
                codigoshas_inicial: registro.codigoshas_inicial,
                fecha: registro.fecha,
                nombre_archivo: registro.nombre_archivo,
                tamano: registro.tamano,
                ruta: registro.ruta,
                folio: registro.folio,
                num_certificado: registro.num_certificado,
                transaccion: registro.transaccion,
                codigosha: registro.codigosha,
                plantilla: registro.plantilla,
                firmante: registro.firmante
              },
              fetchPolicy: 'no-cache',
        }).pipe(
            map( ({data}) =>{
                if (data ){
                    return data;
                }
                 return null;
            })

        );
    }




    updateEstado(id_registro: number,estado: number)  {
        return this.apollo.mutate({
            mutation: gql`
            mutation updateEstadoRegistro(
              $id_registro: Int!
              $estado: Int!
            ){
                updateEstadoRegistro(
                id_registro: $id_registro,
                estado: $estado
              )
            }                
            `,
            variables: {
              id_registro: id_registro,
              estado: estado
            },
            fetchPolicy: 'no-cache',
        }).pipe(
            map(({ data }) => {
                //this.logger.debug("registro: "+JSON.stringify(data));
                if (data) {
                    return data['updateEstadoRegistro'];
                }
                return null;
            })
  
        );
      }
  



}
