import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Usuario } from '../model/usuario';

@Injectable({ providedIn: 'root' })
export class LoginService {
    private currentUserSubject: BehaviorSubject<Usuario>;
    private currentURLSubject: BehaviorSubject<string>;
    public currentUser: Observable<Usuario>;
    public currentURL: Observable<String>;

    constructor(private http: HttpClient, private apollo: Apollo) {
        this.currentUserSubject = new BehaviorSubject<Usuario>(JSON.parse(sessionStorage.getItem('usuario')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.currentURLSubject = new BehaviorSubject<string>(null);
        this.currentURL = this.currentURLSubject.asObservable();
        this.currentURLSubject.next(null);
    }

    public get currentUserValue(): Usuario {
        return this.currentUserSubject.value;
    }

    public get currentURLValue(): string {
        return this.currentURLSubject.value;
    }

    public set currentURLValue(value: string) {
        this.currentURLSubject.next(value);
    }


    login(usuario: string, clave: string) {

        return this.apollo.watchQuery<any>({
            query: gql`
              query getUsuariobyClave($clave: String!,$contrasena: String!)
            { 
                getUsuariobyClave (clave: $clave, contrasena: $contrasena) {
                    identificador,
                    id_tipo_usuario,
                    clave,
                    contrasena,
                    nombre,
                    ape_paterno,
                    ape_materno,
                    rfc,
                    correo,
                    status,
                    fecha_alta
                }
            }
            `,
            variables: {
                clave: usuario,
                contrasena: clave,

            },
        }).valueChanges.pipe(
            map(({ data }) => {
                if (data) {
                    sessionStorage.setItem('usuario', JSON.stringify(data.getUsuariobyClave));
                    this.currentUserSubject.next(data.getUsuariobyClave);
                    this.currentURLSubject.next("");
                }
                return data;
            }
            )

        );
    }

    logout() {
        sessionStorage.removeItem('usuario');
        this.currentUserSubject.next(null);
        this.currentURLSubject.next(null);
    }
}