import { Component, OnInit,ChangeDetectorRef,AfterViewInit } from '@angular/core';
import { CommonsShared } from '../commons/commons.shared';
import { Location } from '@angular/common';
import { fromEvent } from 'rxjs';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from './../environments/environment';
import { NGXLogger } from 'ngx-logger';
import * as $ from "jquery"



import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'CURSOS | Administración de cursos';
  private _initWinHeight = 0;
  navOpen:     boolean;
  minHeight:   string;  

  constructor(private router: ActivatedRoute,private route : Router,private cdRef:ChangeDetectorRef,private commons: CommonsShared, private location : Location,private logger: NGXLogger ) {
  }

  ngOnInit() {
    //this.logger.debug('parametro:'+(new URLSearchParams(this.location['_platformStrategy']._platformLocation.search)).get('nsession'));
    //this.logger.debug('loca: '+JSON.stringify(this.location['_platformStrategy']._platformLocation));
    let navigate =this.location['_platformStrategy']._platformLocation. pathname;
    let parametro=(new URLSearchParams(this.location['_platformStrategy']._platformLocation.search)).get('nsession');

    if (navigate && navigate=='/firmaInvolucrado' && parametro){
      this.commons.currentnReunionValue =decodeURIComponent((new URLSearchParams(this.location['_platformStrategy']._platformLocation.search)).get('nsession'));
      this.route.navigate(['firmaInvolucrado']);
    }else if (navigate && navigate=='/revisionAcuses' && parametro){
      this.commons.currentnReunionValue =decodeURIComponent((new URLSearchParams(this.location['_platformStrategy']._platformLocation.search)).get('nsession'));
      this.route.navigate(['revisionAcuses']);
    }else{
      this.route.navigate(['firma']);
    }

    fromEvent(window, 'resize')
      .pipe(
        debounceTime(200)
      )
      .subscribe((event) => this._resizeFn(event));

    this._initWinHeight = window.innerHeight;
    this._resizeFn(null);
  }

  ngAfterViewInit(): void {
    $( "#sfpSign" ).attr("data-info", environment.APIFirmaCliente_id+","+environment.APIFirmaSecretKey+",1");
  }  

  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  navToggledHandler(e: boolean) {
    this.navOpen = e;
  }

  private _resizeFn(e) {
    const winHeight: number = e ? e.target.innerHeight : this._initWinHeight;
    this.minHeight = `${winHeight}px`;
  }

}

/*


  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand;


  registroForm: FormGroup;
  busquedaForm: FormGroup;
  pdfFonts;
  datosRegisto: Registro = new Registro();
  submittedRegistro = false;
  pageTitle = 'Firma electrónica de archivos';
  title = 'FIRMA |Conciliaciones';
  nombre_sp='';
  private _initWinHeight = 0;
  navOpen:     boolean;
  minHeight:   string;  
  url_href = '';
  loading = false;
  pantalla='Formulario';
  pantallAnt='Formulario';
  parametosFirm = {
    cadena:'Probando ando',
    rfc: 'FORJ710622N36'
    };
  paramDiv='21133,VDXR3ZWDC34HOP58OP7HX,1';
  msgs = [];
  listRegistros: any = [];
  ejercicios: any = [];
  columnas = [
          { field: 'nombre_reunion', header: 'Nombre de la reunión' },
          { field: 'nombre_archivo', header: 'Nombre del Archivo' },
          { field: 'transaccion', header: 'No. Transacción' },
          { field: 'fecha', header: 'Fecha de Firma' },
          { field: 'id_registro', header: 'Opciones' }
          ];
  first = 0;
  rows = 10;
  uploadedFiles: any[] = [];
  fecha: Date;
  archivo: any;

  constructor(private messageService: MessageService, private ngZone: NgZone,private registroService: RegistroService, 
    private formBuilder: RxFormBuilder, platformLocation: PlatformLocation, private callModulosService: CallModulosService, 
    private cdRef: ChangeDetectorRef,private route: ActivatedRoute ) {

    this.logger.debug((platformLocation as any).location)
    this.logger.debug((platformLocation as any).location.href)
    this.logger.debug((platformLocation as any).location.origin)
    this.url_href =(platformLocation as any).location.href;
      this.pdfFonts = require('pdfmake/build/vfs_fonts.js');
      PdfMakeWrapper.setFonts(this.pdfFonts);
    }

    ngAfterViewInit () {
      this.cdRef.detectChanges();
      setTimeout(() => {
        this.pdfViewerOnDemand.pdfSrc='';
        this.pdfViewerOnDemand.refresh();
    });
      
    }

    cambiaEjercicio(event){
      this.logger.debug('event :' + event);
      this.logger.debug(event.value.value);
      this.llenaRegistro(this.fb.rfc_busqueda.value,event.value.value);
    }


    next() {
      this.first = this.first + this.rows;
    }

    prev() {
      this.first = this.first - this.rows;
    }


  ngOnInit() {
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(200)
      )
      .subscribe((event) => this._resizeFn(event));

    this.route.paramMap.subscribe(params => {
        this.logger.debug(params)
    });

    this._initWinHeight = window.innerHeight;
    this._resizeFn(null);
    this.datosRegisto = new Registro();
    this.registroForm = this.formBuilder.formGroup(this.datosRegisto);
    this.busquedaForm = new FormGroup({
      ejercicioSeleccionado: new FormControl(),
      rfc_busqueda: new FormControl()
    });    
    this.submittedRegistro = false;

    var url = "https://code.jquery.com/color/jquery.color.js";
    $.getScript( url, function() {
      $( "#sfpSignScript" ).attr("src", "https://ejzpriv-firma-electronica-firmafront-staging.apps.funcionpublica.gob.mx/assets/plugin/sfpsign.js");
    });

    window['angularComponentReference'] = { component: this, zone: this.ngZone, loadAngularFunction: (dato) => this.angularFunctionCalled(dato), };  
    $("#sfpSignButton").hide();
  }  

  angularFunctionCalled(dato) {  
    //this.logger.debug("datos: "+JSON.stringify(dato));
    if (dato.respuestaValidacion!=='EL USUARIO HA CANCELADO EL PROCESO DE FIRMADO'){
      const reader = new FileReader();
      reader.readAsDataURL(this.archivo);
      reader.onload = () => {
        //this.callModulosService.callsendDocumento( btoa(reader.result.toString()),this.archivo.name);
        //this.callModulosService.callsendDocumento( reader.result,this.archivo.name);

        var datosRegistoTmp = new Registro();
        datosRegistoTmp.rfc_firma=dato.rfc;
        datosRegistoTmp.folio=dato.folio;
        datosRegistoTmp.num_certificado=dato.numeroCertificado;
        datosRegistoTmp.transaccion=dato.transaccion;
        datosRegistoTmp.codigosha=dato.codigoSha;

        datosRegistoTmp.id_registro=new Uint8Array(0);
        datosRegistoTmp.nombre_firma=this.f.nombre_firma.value;
        datosRegistoTmp.nombre_reunion=this.f.nombre_reunion.value;
        datosRegistoTmp.codigoshas_inicial=this.parametosFirm.cadena;
        datosRegistoTmp.fecha=this.fecha;
        datosRegistoTmp.nombre_archivo=this.archivo.name;
        datosRegistoTmp.tamano=this.archivo.size;
        datosRegistoTmp.ruta=moment().format('YYYY');
        this.submittedRegistro = false;


        this.msgs =[];
        this.registroService.addRegistro(datosRegistoTmp,this.archivo)
        .pipe(first())
          .subscribe(
            data => {
              this.logger.debug(JSON.stringify(data));
              this.loading = false;
              if(data.hasOwnProperty('addRegistro')){
                this.cargaRegistro(data["addRegistro"]);
              }
              $( "#sfpSign" ).hide();
              this.limpiaRegistro();    
              $("#regisForm").show();
          
            },
            error => {
              this.msgs.push({severity:'error', summary:'Registro de la firma', detail:'No se pudo dar de alta el registro de esta firma'});
              //this.messageService.add({severity: 'error', summary: 'Registro de la firma', detail: 'No se pudo dar de alta el registro de esta firma'});
              this.loading = false;
              $( "#sfpSign" ).hide();
              this.limpiaRegistro();    
              $("#regisForm").show();
            });
      }
    }else{
      $( "#sfpSign" ).hide();
      this.limpiaRegistro();    
      $("#regisForm").show();
     
    }
    $("#sfpSignButton").prop('disabled', false);
  } 

  onBlurRFC(){
    this.logger.debug(this.fb.rfc_busqueda.value);
    this.fb.ejercicioSeleccionado.setValue({label:moment().format('YYYY'),value:parseInt(moment().format('YYYY'), 10)});
    this.llenaEjercicios(this.fb.rfc_busqueda.value);
    this.llenaRegistro(this.fb.rfc_busqueda.value,parseInt(moment().format('YYYY'), 10));
    
  }


  private _resizeFn(e) {
    const winHeight: number = e ? e.target.innerHeight : this._initWinHeight;
    this.minHeight = `${winHeight}px`;
  }  

  onSelectImage(event) {
      this.archivo = event[0];
      this.fecha = new Date();
  }


  onRemoveImage(event) {
    $( "#sfpSign" ).hide();
    this.uploadedFiles = [];
  }


  onSubmitFirma($event){
    this.logger.debug("Submit");
    $event.preventDefault();
    this.msgs = [];
    this.submittedRegistro = true;

    if (!this.archivo){
      this.msgs.push({severity:'info', summary:'Falta capturar información', detail:'Capture el archivo que desea firmar'});
      //alert("Capture el archivo que desea firmar")
    }else{


/*    if (this.registroForm.invalid) {
      alert("Capture los datos faltantes")
      for (const name in this.registroForm.controls) {
        if (this.registroForm.controls[name].invalid) {
          alert("invalido: "+name );
        }
      }
      return;
    }* aquiii/
    if (!this.registroForm.invalid) {
      if($("#sfpSignButton"). length){
        this.logger.debug("rfc: "+this.datosRegisto.rfc_firma);
        this.parametosFirm.cadena = CryptoJS.SHA256(this.datosRegisto.rfc_firma+"|"+this.archivo.name+"|"+this.archivo.size+"|"+this.fecha).toString(CryptoJS.enc.Hex);
        this.parametosFirm.rfc = this.datosRegisto.rfc_firma;
        cambiaDatosFE.cambiaDatos(this.parametosFirm);
        $("#regisForm").hide();
        $("#sfpSignButton").click();
        this.logger.debug("Despues del click");
      }else{
        this.msgs.push({severity:'error', summary:'Error en la aplicación', detail:'No se cargo el componente firma electrónica'});
        //alert("No encontro el componente necesario")
      }
    }
  }
  }

  get f() { return this.registroForm.controls; }
  get fb() { return this.busquedaForm.controls; }

  limpiaRegistro(){
    this.f.rfc_firma.setValue("");
    this.f.nombre_firma.setValue("");
    this.f.nombre_reunion.setValue("");
    this.msgs = [];
    this.uploadedFiles = [];
    this.submittedRegistro = false;
  }

  limpiaBusqueda(){
    this.ejercicios=[];
    this.fb.ejercicioSeleccionado.setValue({});
    this.fb.rfc_busqueda.setValue("");
    this.nombre_sp='';
    this.listRegistros = [];
  }


  async getImg() {
    return  await new Img(this.url_href+'assets/images/escudo.jpg').build();
  }

  promiseLoadPdf = (pdfViewerOnDemand) => {
    return new Promise((resolve, reject) => {
        const pdf: PdfMakeWrapper = new PdfMakeWrapper();
        pdf.pageSize({
          width: 595.28,
          height: 'auto'
        });

        new Img(this.url_href+'assets/images/escudoBG.png').absolutePosition(50, 100).build().then( img => {
          pdf.background(img);
          new Img(this.url_href+'assets/images/escudo.jpg').fit([200,200]).absolutePosition(10, 10).build().then( img2 => {
            pdf.add(img2);
            pdf.add(new Txt('Sistema de Conciliaciones').alignment('center').italics().end);
            pdf.add(new Txt('Acuse de Firma Electrónica Sistema de Conciliaciones').alignment('center').italics().end);
    
            var tabla =new Table([
              [ 'Nombre del Responsable:                                          ', this.datosRegisto.nombre_firma],
              [ 'RFC:                                                             ', this.datosRegisto.rfc_firma],
              [ 'Nombre del Archivo firmado:                                      ', this.datosRegisto.nombre_archivo],
              [ 'Tamaño del Archivo firmado:                                      ', this.datosRegisto.tamano],
              [ 'Fecha de firma:                                                  ', moment(this.datosRegisto.fecha).format("DD-MM-YYYY")+",   Hora:   "+moment(this.datosRegisto.fecha).format("HH:mm:ss")],
              
            ]).widths([ '40%', '60%']).layout('noBorders').absolutePosition(50, 200).end;

            pdf.add(tabla);     

            pdf.add(new Txt('Cadena de autentificación:').alignment('center').italics().absolutePosition(20, 500).end);
            pdf.add(new Txt(CryptoJS.SHA256(this.datosRegisto.rfc_firma+"|"+this.datosRegisto.nombre_archivo+"|"+this.datosRegisto.tamano+"|"+this.datosRegisto.fecha).toString(CryptoJS.enc.Hex)).alignment('center').italics().absolutePosition(20, 515).end);
            pdf.create().getBuffer(function(buffer) {
              pdfViewerOnDemand.pdfSrc= buffer;
              resolve(true);
            });
        
          });
        });
      })
  }

  cierraVisor(){
    this.limpiaRegistro(); 
    this.pantalla=this.pantallAnt;
  }


  buscaAcuse(){
    this.pantalla="Busqueda";
    this.pantallAnt="Busqueda";
    this.limpiaBusqueda();
  }

  salirdeBusqueda(){
    this.pantalla="Formulario";
    this.pantallAnt="Formulario";
    this.limpiaBusqueda();
  }

  llenaRegistro(rfc,ejercicio){
    this.loading = true;
    this.msgs=[];
    this.registroService.getRegistros(rfc,ejercicio)
    .pipe(first())
    .subscribe(
        data => {
          this.listRegistros=data;
          if (Array.isArray(this.listRegistros) && this.listRegistros.length  ){
            this.nombre_sp=this.listRegistros[0].nombre_firma;
          }else
            this.nombre_sp='';
          this.loading = false;
        },
        error => {
          this.msgs.push({severity:'error', summary:'Error al cargar la información', detail:'No se pudo accesar a la BD: '+ error});
            this.loading = false;
        });
  }

  llenaEjercicios(rfc){
    this.loading = true;
    this.registroService.getEjercicios(rfc)
    .pipe(first())
    .subscribe(
        data => {
          this.ejercicios=data;
          this.loading = false;
        },
        error => {
           this.msgs.push({severity:'error', summary:'Error al cargar información', detail:'No se pudo cargar los ejercicios: '+ error});
           this.loading = false;
        });
  }



   cargaRegistro(id_registro){
    this.msgs = [];
    this.loading = true;
    this.registroService.getRegistrobyId(id_registro)
    .pipe(first())
    .subscribe(
      data => {
        this.logger.debug(JSON.stringify(data));
        try{
          this.datosRegisto.rfc_firma=data.rfc_firma;
          this.datosRegisto.folio=data.folio;
          this.datosRegisto.num_certificado=data.numeroCertificado;
          this.datosRegisto.transaccion=data.transaccion;
          this.datosRegisto.codigosha=data.codigoSha;
          this.datosRegisto.id_registro=id_registro;
          this.datosRegisto.nombre_firma=data.nombre_firma;
          this.datosRegisto.nombre_reunion=data.nombre_reunion;
          this.datosRegisto.codigoshas_inicial=data.codigoshas_inicial;
          this.datosRegisto.fecha=data.fecha;
          this.datosRegisto.nombre_archivo=data.nombre_archivo;
          this.datosRegisto.tamano=data.tamano;
          this.datosRegisto.ruta=data.ruta;
          try{
            this.promiseLoadPdf(this.pdfViewerOnDemand).then(() =>{
              this.pdfViewerOnDemand.refresh();
              this.loading = false;
              this.pantalla="Acuse";
            });
          }catch (e) {
            this.msgs.push({severity:'error', summary:'Error al cargar el acuse', detail:'No pudo crear el acuse: '+ e});
            this.logger.error(e)
            this.loading = false;
          }
        }catch (e) {
          this.msgs.push({severity:'error', summary:'Error al cargar el registro de firma', detail:'No cargar el registro: '+ e});
          this.loading = false;
          this.logger.error(e)
          //alert('Error al cargar el registro de firma.');  
        }

      },
      error => {
        this.msgs.push({severity:'error', summary:'Error al cargar el registro de firma en la BD:', detail:error});
        //alert('Error al cargar el registro de firma en la BD: '+error );  
      });
  }

  getArchivo(id_registro): void {
    this.loading = true;
    this.registroService.getArchivobyId(id_registro).subscribe(
      response => {
        //this.logger.debug(response.nombre_archivo);
        //this.logger.debug(response.archivo);
        if (response.archivo!="false"){
          const newBlob = new Blob([(Buffer.from(response.archivo, 'base64'))], { type: 'application/octet-stream' });
          const element = document.createElement('a');
          element.href = URL.createObjectURL(newBlob);
          element.download = response.nombre_archivo;
          document.body.appendChild(element);
          element.click();
        }else{
          this.msgs.push({severity:'error', summary:'Error al bajar el archivo:', detail:"No existe el archivo registrado en el repositorio"});
        }
        this.loading = false;
      },
      error =>{
        this.msgs.push({severity:'error', summary:'Error al bajar el archivo:', detail:error});
        this.loading = false;
        //alert('Error al bajar el archivo');
   });    
  } 


  envioArchivo(){
    var stringBase64: string;


    const reader = new FileReader();
    reader.readAsDataURL(this.archivo);
    reader.onload = () => {
      //this.callModulosService.callsendDocumento( btoa(reader.result.toString()),this.archivo.name);
      //this.callModulosService.callsendDocumento( reader.result,this.archivo.name);


      var datosRegistoTmp = new Registro();
      datosRegistoTmp.rfc_firma="FORJ710622N36";
      datosRegistoTmp.folio="12345678";
      datosRegistoTmp.num_certificado="invetsado";
      datosRegistoTmp.transaccion=this.archivo.size;
      datosRegistoTmp.codigosha="dsdsdsd";

      datosRegistoTmp.id_registro=new Uint8Array(0);
      datosRegistoTmp.nombre_firma="Jorge Flores ";
      datosRegistoTmp.nombre_reunion="reunion";
      datosRegistoTmp.codigoshas_inicial="inicial";
      datosRegistoTmp.fecha=new Date();
      datosRegistoTmp.nombre_archivo=this.archivo.name;
      datosRegistoTmp.tamano=this.archivo.size;
      datosRegistoTmp.ruta=moment().format('YYYY');;


      this.msgs= [];
      this.registroService.addRegistro(datosRegistoTmp,this.archivo)
        .pipe(first())
          .subscribe(
            data => {
              this.logger.debug(JSON.stringify(data));
            },
            error => {
              alert('Error al registrar la firma en la BD: '+error );  
            });

    };
  }

}*/
