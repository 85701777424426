import { Component, OnInit,AfterViewInit,ViewChild,ChangeDetectorRef } from '@angular/core';
import { FormGroup,FormControl} from '@angular/forms';
import { RegistroService } from '../../../services/registro.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { CommonsShared } from '../../../commons/commons.shared';
import { Location } from '@angular/common';
import { Registro } from 'src/model/registro';
import { LoginService } from '../../../services/login.service';
import { NGXLogger } from 'ngx-logger';


@Component({
  selector: 'app-busqueda-acuses',
  templateUrl: './busqueda-acuses.component.html',
  styleUrls: ['./busqueda-acuses.component.css']
})
export class BusquedaAcusesComponent implements OnInit, AfterViewInit {
  
  busquedaForm: FormGroup;
  loading = false;
  veVisor=false;
  veUrl=false;
  msgs = [];
  listRegistros: any = [];
  ejercicios: any = [];
  nombre_sp='';
  stringUrlbase='';
  stringUrl='';
  rowExpanonIniPage=1;    
  first = 0;
  rows = 10;
  rowGroupMetadata: any;
  currentUser: any;
  //RFC: any = JSON.parse(sessionStorage.getItem('usuario'));

  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand;
  

  constructor(private registroService: RegistroService, private cdRef: ChangeDetectorRef,private router: Router,private commons: CommonsShared, 
    private location : Location ,private loginService:LoginService,private logger: NGXLogger ) {
    this.busquedaForm = new FormGroup({
      ejercicioSeleccionado: new FormControl(),
      rfc_busqueda: new FormControl()
    });    
    this.stringUrlbase=this.location['_platformStrategy']._platformLocation.location.protocol+'//'+this.location['_platformStrategy']._platformLocation.location.host;
    this.loginService.currentUser.subscribe(x => this.currentUser = x);
    //this.onBlurRFC();
  }

  cambiaEjercicio(event){
    // this.llenaRegistro(this.RFC.rfc, event.value.value);
    this.llenaRegistro(this.fb.rfc_busqueda.value, event.value.value);
  }

  onBlurRFC(){
    this.fb.ejercicioSeleccionado.setValue({label:moment().format('YYYY'),value:parseInt(moment().format('YYYY'), 10)});
    // this.llenaEjercicios(this.RFC.rfc);
    // this.llenaRegistro(this.RFC.rfc,parseInt(moment().format('YYYY'), 10));
    this.llenaEjercicios(this.fb.rfc_busqueda.value);
    this.llenaRegistro(this.fb.rfc_busqueda.value,parseInt(moment().format('YYYY'), 10));
  }

  llenaRegistro(rfc,ejercicio){
    this.loading = true;
    this.msgs=[];
    this.registroService.getRegistros(rfc,ejercicio)
    .pipe(first())
    .subscribe(
        data => {
          this.listRegistros = [];
          let listRegistrosO=data;
          if (Array.isArray(listRegistrosO) && listRegistrosO.length  ){
            this.nombre_sp=listRegistrosO[0].nombre_firma;
            this.expandeRenglones(listRegistrosO);
          }else
            this.nombre_sp='';
          this.loading = false;
        },
        error => {
          this.msgs.push({severity:'error', summary:'Error al cargar la información', detail:'No se pudo accesar a la BD: '+ error});
            this.loading = false;
        });
  }
  
  llenaEjercicios(rfc){
    this.loading = true;
    this.registroService.getEjercicios(rfc)
    .pipe(first())
    .subscribe(
        data => {
          this.ejercicios=data;
          this.loading = false;
        },
        error => {
           this.msgs.push({severity:'error', summary:'Error al cargar información', detail:'No se pudo cargar los ejercicios: '+ error});
           this.loading = false;
        });
  }
  
  limpiaBusqueda(){
    this.ejercicios=[];
    this.fb.ejercicioSeleccionado.setValue({});
    this.fb.rfc_busqueda.setValue("");
    this.nombre_sp='';
    this.listRegistros = [];
  }

  async  cargaRegistro(id_registro){
    this.loading = true;
    this.msgs =[];
    const uu=await this.commons.cargaRegistroV(id_registro,this.pdfViewerOnDemand);
    if(uu.hasOwnProperty("severity")){
      this.msgs.push(uu);
    }
    this.loading = false;
    this.veVisor =true;
    //this.router.navigate(['visorAcuse']);    
  }


  async  cargaArchivo(id_registro){
    this.loading = true;
    this.msgs =[];
    const uu=await this.commons.cargaRegistroV(id_registro,this.pdfViewerOnDemand);
    if(uu.hasOwnProperty("severity")){
      this.msgs.push(uu);
    }else{
      this.veVisor =true;
    }
    this.loading = false;
    
  }



  generaUrl(id_registro){
    this.veUrl=true;
    this.stringUrl=this.stringUrlbase+'/firmaInvolucrado?nsession='+encodeURIComponent(this.commons.encriptaText(id_registro.toString()));
  }


  cierraVisor(){
    this.veVisor=false;
  }
  
  async getArchivo(id_registro)  {
    this.loading = true;
    this.msgs =[];
    const uu=await this.commons.getArchivo(id_registro);
    if(uu.hasOwnProperty("severity")){
      this.msgs.push(uu);
    }else{
      this.pdfViewerOnDemand.pdfSrc= uu;
      this.pdfViewerOnDemand.refresh();      
      this.veVisor =true;
    }
    this.loading = false;
  } 

  async getPlantilla(id_registro)  {
    this.loading = true;
    this.msgs =[];
    const uu=await this.commons.cargaPlantilla(id_registro,this.pdfViewerOnDemand);
    if(uu.hasOwnProperty("severity")){
      this.msgs.push(uu);
    }
    this.loading = false;
    this.veVisor =true;
    //this.router.navigate(['visorAcuse']);    
  }


  async dejarCompartir(registro){
    this.loading = true;
    this.msgs =[];
    //this.logger.debug("regsitro: "+registro.id_registro);
    const uu=await this.commons.actualizaEstado(registro.id_registro,1);
    //this.logger.debug("uu: "+JSON.stringify(uu));
    if(uu.hasOwnProperty("severity")){
      this.msgs.push(uu);
    }else{
      registro.estado=1;
    }
    this.loading = false;
  }

  salirdeBusqueda(){
    this.limpiaBusqueda();
    this.router.navigate(['firma']);   
  }
    
  get fb() { return this.busquedaForm.controls; }

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  ngOnInit(): void {

  }

  ngAfterViewInit () {
    this.cdRef.detectChanges();
  }

  setMyPagination(event) {
    //event.first: Index of first record being displayed 
    //event.rows: Number of rows to display in new page 
    //event.page: Index of the new page 
    //event.pageCount: Total number of pages
    //this.logger.debug("event: "+JSON.stringify(event));

    this.rowExpanonIniPage=1;
    if (event.first>0 && this.rowGroupMetadata[this.listRegistros[event.first].id_registro].size>1){
      let idRegRow=this.listRegistros[event.first].id_registro;
      this.rowExpanonIniPage=this.rowGroupMetadata[this.listRegistros[event.first].id_registro].size;
      for (let i=event.first; i>=0; i--){
        if (idRegRow!== this.listRegistros[i].id_registro)
          break;
        this.rowExpanonIniPage--;
      }
      this.rowExpanonIniPage++;
    }
}

  expandeRenglones(listRegistros){
    this.rowGroupMetadata = {};
    for (let i = 0; i < listRegistros.length; i++) {
      this.listRegistros.push(listRegistros[i]);
      if (Array.isArray(listRegistros[i].involucrados) && listRegistros[i].involucrados.length  ){
        for (let j = 0; j < listRegistros[i].involucrados.length; j++) {
          let nuevoReg = new Registro();
          nuevoReg.id_registro=listRegistros[i].id_registro;
          nuevoReg.rfc_firma= listRegistros[i].involucrados[j].rfc_firma;
          nuevoReg.nombre_firma= listRegistros[i].involucrados[j].nombre_firma;
          nuevoReg.nombre_reunion=listRegistros[i].nombre_reunion;
          nuevoReg.codigoshas_inicial= listRegistros[i].involucrados[j].codigoshas_inicial;
          nuevoReg.fecha= listRegistros[i].involucrados[j].fecha;
          nuevoReg.nombre_archivo=listRegistros[i].nombre_archivo;
          nuevoReg.tamano=listRegistros[i].tamano;
          nuevoReg.ruta=listRegistros[i].ruta;
          nuevoReg.folio=listRegistros[i].involucrados[j].folio;
          nuevoReg.num_certificado=listRegistros[i].involucrados[j].num_certificado;
          nuevoReg.transaccion=listRegistros[i].involucrados[j].transaccion;
          nuevoReg.codigosha=listRegistros[i].involucrados[j].codigosha;
          this.listRegistros.push(nuevoReg);
        }
      }
    }

    for (let i = 0; i < this.listRegistros.length; i++) {

        let rowData = this.listRegistros[i];
        let nombre_grupo = rowData.id_registro;
        if (i == 0) {
            this.rowGroupMetadata[nombre_grupo] = { index: 0, size: 1 };
        }
        else {
            let previousRowData = this.listRegistros[i - 1];
            let previousRowGroup = previousRowData.id_registro;
            if (nombre_grupo === previousRowGroup)
                this.rowGroupMetadata[nombre_grupo].size++;
            else
                this.rowGroupMetadata[nombre_grupo] = { index: i, size: 1 };
        }
    }

  }


  cierraSession(){
    this.loginService.logout();
    this.router.navigate(['/login']);
    this.currentUser = this.loginService.currentUserValue;

  }

}


