<div id="formulario"  class="formulario" >
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="row flex-xl-nowrap">
      <div class="col-12 col-md-3 col-xl-12 bd-sidebar"> </div>
      <div class="col-12 col-md-12 col-xl-12 py-md-12 pl-md-5 bd-content ">
        <h1 class="bd-title" id="content">Acceso al Sistema</h1>
        <div >
          <div class="form-group">
            <label for="username">Usuario:</label>
            <input type="text" class="form-control" id="username" formControlName="username" placeholder="Clave del usuario" required
                [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
            <div *ngIf=" submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Usuario requrido</div>
            </div>
          </div>
          <div class="form-group">
            <label for="password">Contraseña:</label>
            <input type="password" class="form-control" id="password" formControlName="password"  placeholder="Contraseña" required
            [ngClass]="{ 'is-invalid': submitted &&  f.password.errors }" >
            <div *ngIf="submitted &&  f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Contraseña requerida</div>
            </div>
          </div>

        </div>
        <div class="modal-footer " >
          <button type="button" class="btn btn-primary" (click)="limpiar($event)"  > Limpiar</button>
          <button type="submit" [disabled]="loading" class="btn btn-success ">Entrar</button>&nbsp;&nbsp;&nbsp;
        </div>
        <div class="modal-footer justify-content-between" >
          <img *ngIf="loading"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          <div *ngIf="error" class="alert alert-danger">{{error}}</div>
        </div>
      </div>

    </div>
  </form>
</div>

