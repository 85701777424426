import { required,maxLength,propObject,numeric,NumericValueType } from "@rxweb/reactive-form-validators";

export class Usuario {
    @required()
    identificador: number;
    @required()
    id_tipo_usuario: number;
    @required()
    nombre : string;
    @required()
    ape_paterno: string;
    ape_materno: string;
    @required()
    rfc: string;
    @required()
    correo: string;
    @required()
    clave: string;
    @required()
    contrasena: string;
    @required()
    status: number;
    @required()
    fecha_alta: Date;
    error: string;
}
