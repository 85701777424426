
export class Involucrado{
    id_registro:Number;
    id_involucrado:Number;
    rfc_firma: string;
    nombre_firma: string;
    codigoshas_inicial: string
    fecha: Date;
    folio: string;
    num_certificado: string;
    transaccion:Number;
    codigosha: string;
    firmante: string;
  }
  
