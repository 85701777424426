import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FileUploadModule} from 'primeng/fileupload';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MessageService} from 'primeng/api';
import { HttpClientModule, HttpHeaders} from '@angular/common/http';
import { environment } from './../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { TableModule } from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import { FirmaComponent } from './pages/firma/firma.component';
import { BusquedaAcusesComponent } from './pages/busqueda-acuses/busqueda-acuses.component';
import { LoginComponent } from './pages/login/login.component';
import { FirmaInvolucradoComponent } from './pages/firma-involucrado/firma-involucrado.component';
import { RevisionAcusesComponent } from './pages/revision-acuses/revision-acuses.component';
import { AppRoutingModule }        from './app-routing.module';
import {DialogModule} from 'primeng/dialog';
import { FormsModule }   from '@angular/forms';
import { UsuarioComponent } from './pages/usuario/usuario.component';
import { LoggerModule } from 'ngx-logger';
import { NgxLoggerLevel } from 'ngx-logger';


@NgModule({
  declarations: [
    AppComponent,
    FirmaComponent,
    BusquedaAcusesComponent,
    FirmaInvolucradoComponent,
    LoginComponent,
    UsuarioComponent,
    RevisionAcusesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FileUploadModule,
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    FormsModule,
    PdfJsViewerModule,
    TableModule,
    ButtonModule, 
    DropdownModule,
    AppRoutingModule,
    DialogModule,
    LoggerModule.forRoot({
      serverLoggingUrl: environment.apiLoggerUrl,
      level: environment.logLevel,
      serverLogLevel: environment.serverLogLevel,
      disableConsoleLogging: false
    })
  ],
  providers: [MessageService],
  bootstrap: [AppComponent]
})
export class AppModule  {
  constructor(apollo: Apollo, httpLink: HttpLink) {
    apollo.create({
      link: httpLink.create({
        uri: environment.APIEndpointBack,
        headers: new HttpHeaders({'Access-Control-Allow-Origin': '*'})
      }),
      cache: new InMemoryCache({addTypename: false}),
      defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
        }
        /*,
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        },
        mutate: {
            errorPolicy: 'all'
        }*/
      }
    });
  }
}
