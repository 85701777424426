import { Component, OnInit,AfterViewInit,ViewChild, NgZone, ChangeDetectorRef,ElementRef } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { RegistroService } from '../../../services/registro.service';
import { Registro } from '../../../model/registro';
import { RegistroF } from '../../../model/registroF';
import { Involucrado } from '../../../model/involucrados';
import { first } from 'rxjs/operators';
import { FormGroup} from '@angular/forms';
import * as $ from "jquery"
import * as CryptoJS from 'crypto-js';
import { CommonsShared } from '../../../commons/commons.shared';
import { environment } from './../../../environments/environment';
import { NGXLogger } from 'ngx-logger';

declare var cambiaDatosFE: any;


@Component({
  selector: 'app-firma-involucrado',
  templateUrl: './firma-involucrado.component.html',
  styleUrls: ['./firma-involucrado.component.css']
})
export class FirmaInvolucradoComponent implements OnInit, AfterViewInit {

  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand;
  registroForm: FormGroup;
  datosReunion: Registro = new Registro();
  datosRegistoF: RegistroF = new RegistroF();
  submittedRegistro = false;
  pageTitle = 'Firma electrónica de archivos';
  title = 'FIRMA |Conciliaciones';
  nombre_sp='';
  private _initWinHeight = 0;
  navOpen:     boolean;
  minHeight:   string;  
  loading = false;
  parametosFirm = {
    cadena:'Probando ando',
    rfc: 'FORJ710622N36'
    };
  msgs = [];
  fecha: Date;
  archivo: any;
  dicionarFir = false;
  columnas = [
    { field: 'nombre_firma', header: 'Nombre del firmante' },
    { field: 'rfc_firma', header: 'RFC del firmante' },
    { field: 'fecha', header: 'Fecha de Firma' },
    { field: 'transaccion', header: 'Transacción' },
    { field: 'num_certificado', header: 'Certificado' },
    { field: 'firmante', header: 'Firmante' }
  ];
  first = 0;
  rows = 10;
  veVisor=false;
  adicionarFir= false;
  habilitado: Number =2;
  lista: Array<any>;

  constructor(private ngZone: NgZone,private registroService: RegistroService,private elementRef: ElementRef, 
    private formBuilder: RxFormBuilder, platformLocation: PlatformLocation,private logger: NGXLogger, 
    private cdRef: ChangeDetectorRef,private router: Router,private commons: CommonsShared ) {
      try{
        let nsesion=this.commons.currentnReunionValue;
        let sidRegistro = this.commons.dencriptaText(nsesion);
        this.cargaReunion(parseInt(sidRegistro));
      }catch(e){
        this.msgs.push({severity:'error', summary:'Error en la aplicación', detail:'Ocurrio un error:'+e});
      }
  
  }

  ngOnInit(): void {
    this.datosRegistoF = new RegistroF();
    this.registroForm = this.formBuilder.formGroup(this.datosRegistoF);
    this.lista = [
      { text: 'SELECCIONAR', value: '' },
      { text: 'CONCILIADOR', value: 'EL CONCILIADOR' },
      { text: 'EMPRESA', value: 'LA EMPRESA' },
      { text: 'ENTIDAD O DEPENDENCIA', value: 'LA ENTIDAD O DEPENDENCIA' },
      { text: 'ÓRGANO INTERNO DE CONTROL', value: 'EL ÓRGANO INTERNO DE CONTROL' },
    ];
    this.submittedRegistro = false;
    var url = "https://code.jquery.com/color/jquery.color.js";
    $.getScript( url, function() {
      $( "#sfpSignScript" ).attr("src", environment.APIFirma);
    });

    window['angularComponentReference'] = { component: this, zone: this.ngZone, loadAngularFunction: (dato) => this.angularFunctionCalled(dato), };  
    $("#sfpSignButton").hide();

    this.msgs=[];


  }
  async cargaReunion(idRegistro){
    const data=await this.commons.cargaRegistro(idRegistro);
    if(data.hasOwnProperty("severity")){
      //this.logger.error("Error ");
      this.msgs.push(data);
    }else{
      this.datosRegistoF.id_registro=idRegistro;
      this.datosReunion.rfc_firma=data["rfc_firma"];
      this.datosReunion.folio=data["folio"];
      this.datosReunion.num_certificado=data["numeroCertificado"];
      this.datosReunion.transaccion=data["transaccion"];
      this.datosReunion.codigosha=data["codigoSha"];
      this.datosReunion.id_registro=data["id_registro"];
      this.datosReunion.nombre_firma=data["nombre_firma"];
      this.datosReunion.nombre_reunion=data["nombre_reunion"];
      this.f.nombre_reunion.setValue(this.datosReunion.nombre_reunion);
      this.datosReunion.codigoshas_inicial=data["codigoshas_inicial"];
      this.datosReunion.fecha=data["fecha"];
      this.datosReunion.nombre_archivo=data["nombre_archivo"];
      this.datosReunion.tamano=data["tamano"];
      this.datosReunion.ruta=data["ruta"];
      this.datosReunion.involucrados=data["involucrados"];
      this.datosReunion.plantilla=data['plantilla'];
      this.datosReunion.firmante=data['firmante'];
      this.datosReunion.estado=data['estado'];
      this.habilitado=this.datosReunion.estado;
    }
  }

  async angularFunctionCalled(dato) {  
    if (dato.respuestaValidacion!=='EL USUARIO HA CANCELADO EL PROCESO DE FIRMADO'){
      var datosRegistoTmp = new Involucrado();
      datosRegistoTmp.rfc_firma=dato.rfc;
      datosRegistoTmp.folio=dato.folio;
      datosRegistoTmp.num_certificado=dato.numeroCertificado;
      datosRegistoTmp.transaccion=dato.transaccion;
      datosRegistoTmp.codigosha=dato.codigoSha;

      datosRegistoTmp.id_registro=this.datosRegistoF.id_registro;
      datosRegistoTmp.id_involucrado=0;
      datosRegistoTmp.nombre_firma=this.f.nombre_firma.value;
      datosRegistoTmp.codigoshas_inicial=this.parametosFirm.cadena;
      datosRegistoTmp.fecha=this.fecha;
      datosRegistoTmp.firmante=(this.f.firmante.value === '0')? '' : this.f.firmante.value;
      this.submittedRegistro = false;
      this.msgs =[];
      const uu= await this.adicionaRegistro(datosRegistoTmp);
      if(uu.hasOwnProperty("severity")){
        this.msgs.push(uu);
      }else{
        this.cargaReunion(this.datosRegistoF.id_registro);
      }
      $( "#sfpSign" ).hide();
      this.limpiaRegistro();    
      $("#regisForm").show();
    }else{
      $( "#sfpSign" ).hide();
      this.limpiaRegistro();
      $("#regisForm").show();
     
    }
    $("#sfpSignButton").prop('disabled', false);
  } 

  get f() { return this.registroForm.controls; }

  limpiaRegistro(){
    this.f.rfc_firma.setValue("");
    this.f.nombre_firma.setValue("");
    this.f.firmante.setValue("0");
    this.msgs = [];
    this.submittedRegistro = false;
  }

  async onSubmitFirma($event){
    $event.preventDefault();
    this.msgs = [];
    this.submittedRegistro = true;
    if (!this.registroForm.invalid) {

/*      let data=await this.commons.cargaRegistroInv(this.datosRegisto.id_registro,this.datosRegisto.rfc_firma);
      if (data){
        if(data.hasOwnProperty("severity"))
          this.msgs.push(data);
        else
        this.msgs.push({severity:'warn', summary:'Ya existe la firma', detail:'Solo se puede firmar una sola vez el archivo con el mismo RFC'});
      }else{   */
        
          if($("#sfpSignButton"). length){
            this.fecha = new Date();
            this.parametosFirm.cadena = CryptoJS.SHA256(this.datosRegistoF.rfc_firma+"|"+this.datosReunion.nombre_archivo+"|"+this.datosReunion.tamano+"|"+this.fecha).toString(CryptoJS.enc.Hex);
            this.parametosFirm.rfc = this.datosRegistoF.rfc_firma;
            cambiaDatosFE.cambiaDatos(this.parametosFirm);
            $("#regisForm").hide();
            $("#sfpSignButton").click();
            //this.logger.debug("Despues del click");
          }else{
            this.msgs.push({severity:'error', summary:'Error en la aplicación', detail:'No se cargo el componente firma electrónica'});
            //alert("No encontro el componente necesario")
          }
//      }
      this.adicionarFir = false;
    }
  }

  adicionaRegistro(datosRegistoTmp){
    return new Promise((resolve, reject) => {
      this.registroService.addFirma(datosRegistoTmp)
      .pipe(first())
        .subscribe(
          data => {
            //this.logger.debug(JSON.stringify(data));
            this.loading = false;
            if(data.hasOwnProperty('addInvolucrado')){
              resolve(data["addInvolucrado"]);
            } else {
              resolve(false);
            }
          },
          error => {
            resolve({severity:'error', summary:'Registro de la firma', detail:'No se pudo dar de alta el registro de esta firma: '+error});
            //this.msgs.push({severity:'error', summary:'Registro de la firma', detail:'No se pudo dar de alta el registro de esta firma'});
          });
    });
  }

  capturaFir(){
    this.adicionarFir = true;
  }

  async cierraAgrega(){
    this.f.rfc_firma.setValue("");
    this.f.nombre_firma.setValue("");
    this.msgs = [];
    this.adicionarFir = false;
  }

  async  cargaRegistro(){
    this.loading = true;
    this.msgs =[];
    const uu=await this.commons.cargaRegistroV(this.datosReunion.id_registro,this.pdfViewerOnDemand);
    if(uu.hasOwnProperty("severity")){
      this.msgs.push(uu);
    }
    this.loading = false;
    this.veVisor =true;
    this.adicionarFir = false;

    //this.router.navigate(['visorAcuse']);    
  }

  async  cargaPlantilla(){
    this.loading = true;
    this.msgs =[];
    const uu=await this.commons.cargaPlantilla(this.datosReunion.id_registro,this.pdfViewerOnDemand);
    if(uu.hasOwnProperty("severity")){
      this.msgs.push(uu);
    }
    this.loading = false;
    this.veVisor =true;
    this.adicionarFir = false;  
  }

  async  getArchivo(){
    this.loading = true;
    this.msgs =[];
    const uu=await this.commons.getArchivo(this.datosReunion.id_registro);
    if(uu.hasOwnProperty("severity")){
      this.msgs.push(uu);
    }else{
      this.pdfViewerOnDemand.pdfSrc= uu;
      this.pdfViewerOnDemand.refresh();      
      this.veVisor =true;
    }
    this.loading = false;
  }

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  cierraVisor(){
    this.limpiaRegistro(); 
    this.veVisor=false;
  }

  ngAfterViewInit () {
    const s = document.createElement('script'); 
    s.type  = 'text/javascript'; 
    s.src= environment.APIFirma; 
    s.id  = 'sfpSignScript';
     this.elementRef.nativeElement.appendChild(s);
    this.cdRef.detectChanges();
  }

}