<div class="row flex-xl-nowrap" [hidden]="veVisor ">
  <div class="col-12 col-md-9 col-xl-12 py-md-3 pl-md-5 bd-content bg-white">
    <div class="col-12 col-md-3 col-xl-12 bd-sidebar"> </div>
    <h1 class="bd-title" id="content">Datos de la reunión</h1>

    <div class="bd-example">
      <div class="form-group">
        <label for="reunion">Nombre de la reunion</label>
        <input type="text" class="form-control" id="reunion" value="{{datosReunion.nombre_reunion}}" disabled>
      </div>
      <div class="form-group">
        <label for="conciliador">Nombre del conciliador</label>
        <input type="text" class="form-control" id="conciliador" value="{{datosReunion.nombre_firma}}" disabled>
      </div>
      <div class="form-group" *ngIf="datosReunion.plantilla">
        <label for="archivo">Firmante</label>
        <input type="text" class="form-control" id="archivo" value="{{datosReunion.firmante}}" disabled>
      </div>
      <div class="form-group">
        <label for="archivo">Archivo firmado</label>
        <input type="text" class="form-control" id="archivo" value="{{datosReunion.nombre_archivo}}" disabled>
      </div>


      <div *ngIf="datosReunion.plantilla">
        <div class="form-group text-left">
          <label for="validationExpediente"> Expediente </label>
          <input type="text" class="form-control" id="validationExpediente" value="{{plantilla.expediente}}" disabled>
        </div>
        <div class="form-group text-left">
          <label for="validationProCon"> Proveedor o contratista </label>
          <input type="text" class="form-control" id="validationProCon" value="{{plantilla.expProCon}}" disabled>
        </div>
        <div class="form-group text-left">
          <label for="validationEntDep"> Entidad o dependencia </label>
          <input type="text" class="form-control" id="validationEntDep" value="{{plantilla.expEntDep}}" disabled> 
        </div>
        <div class="form-group text-left">
          <label for="validationFecha"> Fecha de la audiencia </label>
          <input type="date" class="form-control" id="validationFecha" value="{{plantilla.expFecha}}" disabled>
        </div>
      </div>







      <div class="form-group">
        <h1 class="bd-title" id="content">Firmantes</h1>
        <div class="table-responsive">
          <p-table [columns]="datosReunion.plantilla ? columnasF : columnas" [value]="datosReunion.involucrados"
            [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
            currentPageReportTemplate="Muestra {first} de {last}, Total de registros {totalRecords} ">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns">
                  {{col.header}}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of columns" style="word-wrap:break-word;">
                  <p *ngIf="col.field!='id_registro'">{{rowData[col.field]}}</p>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>

      </div>


      <div class="modal-footer">
        <button type="button" class="btn btn-success" *ngIf="!datosReunion.plantilla" (click)="getArchivo()"
          [disabled]="!habilitado">Bajar archivo</button>&nbsp;
        <button type="button" class="btn btn-success" *ngIf="!datosReunion.plantilla" (click)="cargaAcuse()"
          [disabled]="!habilitado">Ver acuse</button>&nbsp;
        <button type="button" class="btn btn-success" *ngIf="datosReunion.plantilla" (click)="cargaPlantilla()"
          [disabled]="!habilitado">Ver plantilla</button>&nbsp;
      </div>
    </div>
  </div>
</div>

<div id="visorPdf" class="visor" [hidden]="!veVisor">
  <ng2-pdfjs-viewer #pdfViewerOnDemand style="display: block; height: 100%;"></ng2-pdfjs-viewer>
  <button type="button" class="btn btn-danger" (click)="cierraVisor()">Cerrar acuse</button>&nbsp;
</div>

<div id="Cargando" class="cargando" [hidden]="!loading">
  ....Cargando Información....
</div>

<div id="Error" class="error" [hidden]="!msgs.length">
  <p-messages [(value)]="msgs"></p-messages>
</div>
