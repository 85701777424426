import { required } from "@rxweb/reactive-form-validators";
import { Registro } from './registro';

export class Plantilla{

    @required()
    id_registro: Number;

    @required()
    id_plantilla: Number;

    expediente: String;

    expProCon: String;

    expEntDep: String;

    expFecha: Date;

    textoDoc: String;

    usuario: [Registro];

}
  