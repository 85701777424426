
<div id="busqueda" class="formulario" [hidden]="veVisor">
    <form [formGroup]="busquedaForm" >
      <div class="row flex-xl-nowrap">
        <div class="col-12 col-md-3 col-xl-1 bd-sidebar"> </div>
        <div class="col-12 col-md-9 col-xl-12 py-md-3 pl-md-5 bd-content bg-white">
          <h1 class="bd-title" id="content">Busqueda de acuses</h1>
          <div style="text-align: right; width: 100%;">
            <span *ngIf="currentUser">{{currentUser.nombre+' '+ currentUser.ape_paterno+' '+currentUser.ape_materno}}</span>
          </div>
          <div style="text-align: right; width: 100%;">
            <a [routerLink]="['/login']" class="navbar-text" *ngIf="currentUser!==null" (click)="cierraSession()">
              Cerrar sesión
            </a>
          </div>

          <div class="form-row">
            <div class="col-md-3 mb-3">
                <label for="validationRFC">RFC del conciliador:</label>
            </div>
            <div class="col-md-6 mb-3">
              <input type="text" class="form-control" id="validationRFC" formControlName="rfc_busqueda"  placeholder="RFC del Servidor Público" (blur)="onBlurRFC()" 
              (keyup.enter)="onBlurRFC()" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col-md-3 mb-3">
                <img src="assets/images/intro.png"  height="40px">
            </div>
          </div>

          <div class="form-row"> 
            <div class="col-md-3 mb-3">
              Ejercicio:
            </div>
            <div class="col-md-8 mb-3">
              <p-dropdown [options]="ejercicios" formControlName="ejercicioSeleccionado" optionLabel="label"
              (onChange)="cambiaEjercicio($event)" placeholder="selecciona el ejercicio"
              ></p-dropdown>
            </div>
          </div>
    
          <div class="form-row" >
            <div class="col-md-3 mb-3">
              Nombre del conciliador:
            </div>
            <div class="col-md-8 mb-3" >
              {{nombre_sp}}
            </div>
          </div>
  
        <div class="table-responsive">
          <p-table [value]="listRegistros" [paginator]="true" [rows]="3" [showCurrentPageReport]="true"
          currentPageReportTemplate="Muestra {first} de {last}, Total de registros {totalRecords} " (onPage)="setMyPagination($event)"> 
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th rowspan="2" style="text-align: center;">Nombre del Archivo</th>
                <th rowspan="2" style="text-align: center;">Nombre de la Reunión</th>
                <th colspan="3" style="text-align: center;" >Datos del Firmante</th>
                <th rowspan="2" style="text-align: center;">Opciones</th>
              </tr>
              <tr>
                <th style="text-align: center;">Nombre</th>
                <th style="text-align: center;">RFC</th>
                <th style="text-align: center;">Fecha de firma</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr>
                  <ng-container>
                    <td *ngIf="rowIndex>0 && (rowIndex % 3) === 0 && rowGroupMetadata[listRegistros[rowIndex].id_registro].size >1 && rowGroupMetadata[listRegistros[rowIndex].id_registro].size!=rowExpanonIniPage  " [attr.rowspan]="rowExpanonIniPage"  valign="top"  >
                     &nbsp;
                    </td>
                    <td *ngIf="rowIndex>0 && (rowIndex % 3) === 0 && rowGroupMetadata[listRegistros[rowIndex].id_registro].size >1  && rowGroupMetadata[listRegistros[rowIndex].id_registro].size!=rowExpanonIniPage" [attr.rowspan]="rowExpanonIniPage"  valign="top"  >
                      &nbsp;
                     </td>
 
                      <td *ngIf="rowGroupMetadata[rowData.id_registro].index === rowIndex" [attr.rowspan]="rowGroupMetadata[rowData.id_registro].size" valign="top"  style="word-wrap:break-word;">
                        {{rowData['nombre_archivo']}}
                      </td>
                      <td *ngIf="rowGroupMetadata[rowData.id_registro].index === rowIndex" [attr.rowspan]="rowGroupMetadata[rowData.id_registro].size" valign="top" style="word-wrap:break-word;">
                        {{rowData['nombre_reunion']}}
                      </td>
                      <td valign="top" style="word-wrap:break-word;">
                        {{rowData['nombre_firma']}}
                      </td>
                      <td valign="top" style="word-wrap:break-word;">
                        {{rowData['rfc_firma']}}
                      </td>
                      <td valign="top" style="word-wrap:break-word;">
                        {{rowData['fecha']}}
                      </td>
                      <td *ngIf="rowGroupMetadata[rowData.id_registro].index === rowIndex" [attr.rowspan]="rowGroupMetadata[rowData.id_registro].size" valign="top" style="text-align: center;">
                        <button  pButton type="button" *ngIf="!rowData['plantilla']" label="Bajar archivo" class="ui-button-rounded ui-button-success" (click)="getArchivo(rowData['id_registro'])"></button>
                        <button  pButton type="button" *ngIf="rowData['plantilla']" label="Ver Plantilla" class="ui-button-rounded ui-button-success" (click)="getPlantilla(rowData['id_registro'])"></button>
                        <p *ngIf="!rowData['plantilla']" ></p>
                        <button  pButton type="button" *ngIf="!rowData['plantilla']" label="Ver acuse" class="ui-button-rounded ui-button-success" (click)="cargaRegistro(rowData['id_registro'])"></button>  
                        <p ></p>
                        <button  pButton type="button" label="Agregar firmante" class="ui-button-rounded ui-button-success" (click)="generaUrl(rowData['id_registro'])" *ngIf="rowData['estado']===0"></button> 
                        <p *ngIf="rowData['estado']===0" ></p>
                        <button  pButton type="button" label="Dejar de compartir con firmantes" class="ui-button-rounded ui-button-success" (click)="dejarCompartir(rowData)" *ngIf="rowData['estado']===0"></button> 
                      </td>
                      <td *ngIf="rowIndex>0 && (rowIndex % 3) === 0 && rowGroupMetadata[listRegistros[rowIndex].id_registro].size >1  && rowGroupMetadata[listRegistros[rowIndex].id_registro].size!=rowExpanonIniPage" [attr.rowspan]="rowExpanonIniPage"  valign="top"  >
                        &nbsp;
                       </td>
   
                  </ng-container>
              </tr>
            </ng-template>
          </p-table>
        </div>



        <p></p>      
        <div class="form-row">
          <div class="col-md-12 mb-3" style="text-align: right;">
            <button type="button" class="btn btn-danger" (click)="salirdeBusqueda()">Regresar a la firma de Archivos</button>
          </div>
        </div>
  
      </div>
    </div>
  </form>
</div>


<div id="visorPdf" class="visor" [hidden]="!veVisor">
  <ng2-pdfjs-viewer #pdfViewerOnDemand   style="display: block; height: 100%;" ></ng2-pdfjs-viewer>
  <button type="button" class="btn btn-danger" (click)="cierraVisor()">Cerrar acuse</button>&nbsp;  
</div>  

<div id="Cargando" class="cargando" [hidden]="!loading">
  ....Cargando Información....
</div>

<div id="Error"  class="error" [hidden]="!msgs.length">
  <p-messages [(value)]="msgs"></p-messages>
</div> 


<p-dialog header="Dirección para firmar la reunión-archivo" [(visible)]="veUrl" modal=true>
  <br>
  <p style="font-size: 12;color: forestgreen">El responsable de la firma podrá utilizar el siguiente URL: </p>
  <br>
  <a href="{{stringUrl}}" target="_blank">{{stringUrl}}</a>
  <br>
</p-dialog>