<div id="formulario"  class="formulario" [hidden]="veVisor" >
    <form [formGroup]="registroForm" (ngSubmit)="onSubmitFirma($event)">
      <div class="row flex-xl-nowrap">
        <div class="col-12 col-md-3 col-xl-1 bd-sidebar"> </div>
        <div class="col-12 col-md-12 col-xl-12 py-md-3 pl-md-5 bd-content bg-white">
          <h1 class="bd-title" id="content">{{pageTitle}}</h1>
          <div style="text-align: right; width: 100%;">
            <span *ngIf="currentUser">{{currentUser.nombre+' '+ currentUser.ape_paterno+' '+currentUser.ape_materno}}</span>
          </div>
          <div style="text-align: right; width: 100%;">
            <a [routerLink]="['/login']" class="navbar-text" *ngIf="currentUser!==null" (click)="cierraSession()">
              Cerrar sesión
            </a>
          </div>

          <div class="bd-example">
            <div class="form-group">
              <label for="validationNombre">Nombre completo del servidor público</label>
              <input type="text" class="form-control" id="validationNombre" formControlName="nombre_firma" placeholder="Nombre del Servidor Público" required
                  [ngClass]="{ 'is-invalid': submittedRegistro && f.nombre_firma.errors }">
              <div *ngIf=" submittedRegistro && f.nombre_firma.errors" class="invalid-feedback">
                  <div *ngIf="f.nombre_firma.errors.required">Nombre del servidor público</div>
              </div>
            </div>
            <div class="form-group">
              <label for="validationRFC">RFC</label>
              <input type="text" class="form-control" id="validationRFC" formControlName="rfc_firma"  placeholder="RFC del Servidor Público" required
              [ngClass]="{ 'is-invalid': submittedRegistro &&  f.rfc_firma.errors }" oninput="this.value = this.value.toUpperCase()">
              <div *ngIf="submittedRegistro &&  f.rfc_firma.errors" class="invalid-feedback">
                <div *ngIf="f.rfc_firma.errors.required">RFC del servidor público</div>
              </div>
            </div>
            <div class="form-group">
              <label for="validationFirmante">Firmante</label>
              <select class="form-control" id="validationFirmante" name="miselect" formControlName="firmante" required
              [ngClass]="{ 'is-invalid': submittedRegistro &&  f.firmante.errors }" oninput="this.value = this.value.toUpperCase()">
                <option [value]="item.value.toUpperCase()" *ngFor="let item of lista">{{item.text}}</option>
              </select>
              <div *ngIf="submittedRegistro &&  f.firmante.errors" class="invalid-feedback">
                <div *ngIf="f.firmante.errors.required">Selecciona Firmante</div>
              </div>
            </div>
            <div class="form-group">
              <label for="validationNombreReunion">Identificador de la reunión de conciliación</label>
              <input type="text" class="form-control" id="validationNombreReunion" formControlName="nombre_reunion"  placeholder="Identificador de la reunión" required
              [ngClass]="{ 'is-invalid': submittedRegistro &&  f.nombre_reunion.errors }">
              <div *ngIf="submittedRegistro &&  f.nombre_reunion.errors" class="invalid-feedback">
                <div *ngIf="f.nombre_reunion.errors.required">Identificador de la reunión de conciliación</div>
              </div>
            </div>
  
            <div class="row text-center">
              <div class="col-12">
                <button type="button" class="btn btn-success btnS" (click)="onClickDocumento()"> Subir Documento </button> 
                <button type="button" class="btn btn-success btnC" (click)="onClickPlantilla()"> Crear Plantilla </button> 
              </div>
              <div class="col-12" *ngIf="isActivDP">
                <div class="form-group text-left">
                  <label for="validationExpediente"> Expediente </label>
                  <input type="text" class="form-control" id="validationExpediente" formControlName="expediente"  placeholder="Expediente" required
                  [ngClass]="{ 'is-invalid': submittedRegistro &&  f.expediente.errors }" oninput="this.value = this.value.toUpperCase()">
                  <div *ngIf="submittedRegistro &&  f.expediente.errors" class="invalid-feedback">
                    <div *ngIf="f.expediente.errors.required"> Se requiere Expediente </div>
                  </div>
                </div>
                <div class="form-group text-left">
                  <label for="validationProCon"> Proveedor o contratista </label>
                  <input type="text" class="form-control" id="validationProCon" formControlName="expProCon"  placeholder="Proveedor o contratista" required
                  [ngClass]="{ 'is-invalid': submittedRegistro &&  f.expProCon.errors }" oninput="this.value = this.value.toUpperCase()">
                  <div *ngIf="submittedRegistro &&  f.expProCon.errors" class="invalid-feedback">
                    <div *ngIf="f.expProCon.errors.required"> Se requiere proveedor o contratista </div>
                  </div>
                </div>
                <div class="form-group text-left">
                  <label for="validationEntDep"> Entidad o dependencia </label>
                  <input type="text" class="form-control" id="validationEntDep" formControlName="expEntDep"  placeholder="Entidad o dependencia" required
                  [ngClass]="{ 'is-invalid': submittedRegistro &&  f.expEntDep.errors }" oninput="this.value = this.value.toUpperCase()">
                  <div *ngIf="submittedRegistro &&  f.expEntDep.errors" class="invalid-feedback">
                    <div *ngIf="f.expEntDep.errors.required"> Se requiere entidad o dependencia </div>
                  </div>
                </div>
                <div class="form-group text-left">
                  <label for="validationFecha"> Fecha de la audiencia </label>
                  <input type="date" class="form-control" id="validationFecha" formControlName="expFecha"  placeholder="Fecha de la audiencia" required
                  [ngClass]="{ 'is-invalid': submittedRegistro &&  f.expFecha.errors }">
                  <div *ngIf="submittedRegistro &&  f.expFecha.errors" class="invalid-feedback">
                    <div *ngIf="f.expFecha.errors.required"> Se requiere fecha de la audiencia </div>
                  </div>
                </div>

                <div class="form-group text-left">
                  <label for="validationText"> Texto del documento <!--span class="spanSt">* En caso de estar vacio se dejara el texto de plantilla</span --> </label>
                  <textarea class="form-control" id="validationText" formControlName="textoDoc"  placeholder="Escriba el texto del documento" required
                  [ngClass]="{ 'is-invalid': submittedRegistro &&  f.textoDoc.errors }" oninput="this.value"></textarea>
                  <div *ngIf="submittedRegistro &&  f.textoDoc.errors" class="invalid-feedback">
                    <div *ngIf="f.textoDoc.errors.required"> Se requiere texto del documento </div>
                  </div>
                </div>

              </div>
              <div class="col-12" *ngIf="!isActivDP">
                <div class="col-7">
                  <p-fileUpload name="demo[]" 
                    chooseLabel="Seleccione su archivo"
                    [files]="uploadedFiles" 
                    multiple="false" 
                    accept=".pdf" 
                    maxFileSize="1000000" 
                    [showUploadButton]="false"
                    [showCancelButton]="false" 
                    (onSelect)="onSelectImage($event.files)" 
                    (onRemove)="onRemoveImage($event)"
                    >
                    <ng-template pTemplate="content">
                      <ul *ngIf="uploadedFiles.length">
                        <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                      </ul>
                    </ng-template>
                  </p-fileUpload>  
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="altaUsuario()" *ngIf="currentUser.id_tipo_usuario==1">Adicionar un usuario</button>&nbsp;
            <button type="button" class="btn btn-danger" (click)="limpiaRegistro()">Limpiar</button>&nbsp;
            <button type="submit" class="btn btn-success" >Firmar</button>&nbsp;
            <button type="button" class="btn btn-success" (click)="buscaAcuse()">Buscar Acuse</button>  
    
          </div>
        </div>
  
      </div>
    </form>
  </div>
  
  <div id="visorPdf" class="visor" [hidden]="!veVisor">
    <ng2-pdfjs-viewer #pdfViewerOnDemand   style="display: block; height: 100%;" ></ng2-pdfjs-viewer>
    <button  pButton type="button" label="Agregar firmante" class="ui-button-rounded ui-button-success" (click)="generaUrl()" *ngIf="estado === 0"></button> 
    <button  pButton type="button" label="Dejar de compartir con firmantes" class="ui-button-rounded ui-button-success" (click)="dejarCompartir()" *ngIf="estado === 0"></button> 
    <button type="button" class="btn btn-danger" (click)="cierraVisor()">Cerrar acuse</button>&nbsp;  
  </div>  

  <div id="Cargando" class="cargando" [hidden]="!loading">
      <i class="fa fa-spinner fa-spin" aria-hidden="true">....Cargando Información....</i>
  </div>

  <div id="Error"  class="error" [hidden]="!msgs.length">
    <p-messages [(value)]="msgs"></p-messages>
  </div> 

  <p-dialog header="Dirección para firmar la reunión-archivo" [(visible)]="veUrl" modal=true>
    <br>
    <p style="font-size: 12;color: forestgreen">El responsable de la firma podrá utilizar el siguiente URL: </p>
    <br>
    <a href="{{stringUrl}}" target="_blank">{{stringUrl}}</a>
    <br>
  </p-dialog>